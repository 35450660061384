import React from "react";
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import {
  Dialog,
  DialogContent,
} from "@material-ui/core";
// import { Link } from "react-router-dom";
// import { profile } from 'console';
// import { post } from 'jquery';
import "./styles.css";
import { useTranslation } from 'react-i18next';
// import LinearProgress from '@mui/joy/LinearProgress'

// import { create } from 'domain';

const AdPreviewModal = (props) => {
  const {
    profile,
    // postimg,
  } = useContext(ApiContext);
  const onHandleClose = () => {
    props.handleClose();
  }

  const { t } = useTranslation();

  return (
    <Dialog
      className="animated  p-0"
      open={props.isVisible}
      onClose={() => onHandleClose()}
      aria-labelledby="max-width-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogContent className=" card w-000 shadow-xss rounded-xxl border-0 ps-0 pt-0 pe-0 pb-3 mt-1 mb-1">
      <div
              className="position-absolute right-0 top-5 pointer me-3"
              onClick={() => onHandleClose()}
            >
              <i className="ti-close text-grey-400 fw-700 font-xsss"></i>
            </div>
            {props.item && profile ?
      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-3 pe-3 pt-3 pb-2 mb-3">
      <div className="relative">
        <div className="card-body p-0 d-flex">
          <div>
            <div className="card-body p-0 d-flex">
              {
                profile.img ?
                <figure className="avatar me-3">
                <img
                  src={profile.img}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                />
              </figure>:
              <></>
              }
              
              <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                {" "}
                {profile.nickName}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {props.item.created}
                </span>
              </h4>
            </div>

            <div className="card-body p-0 me-lg-5">
              <p className="fw-600 text-grey-600 lh-26 font-xssss w-100 mb-2">
                {props.item.content}
              </p>
            </div>
          </div>

          <div className="card-body p-0 mb-1 d-flex"></div>
        </div>

        <div className="card shadow-xss rounded-xxl border-light-md pt-2 pb-2 pe-2 ps-2 mb-0">
          <div className="row ps-3 pe-3">
            <div className="col-sm-12 p-1 me-2 ms-0 d-flex  justify-content-center">
              {
                props.item.img ?
                <img
                className="rounded-3 pointer"
                alt="post"
                style={{maxHeight: "400px", maxWidth: "100%"}}
                src={props.item.img}
              />:
              <></>

              }
              
            </div>
          </div>
        </div>

        {/* <div className="card-body d-flex  p-0">
          <div
            className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss me-2"
          >
            <i className="feather-thumbs-up text-dark text-dark text-grey-600 btn-round-sm font-sm"></i>{" "}
            10 Liked
          </div>

          <a
            className="pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss"
          >
            <i className="feather-repeat text-dark text-grey-600 btn-round-sm font-sm"></i>
            <span className="d-none-xss">
              2 Reposted
            </span>
          </a>
        </div> */}
        <div className="position-absolute right-0 top-5 me-1">
          {/* <span className="text-grey-600">{">>>"}</span> */}
          <span className="card  me-1 text-grey-500 fw-600 text-uppercase font-xssss float-left rounded-3 border-dark-md d-inline-block mt-3 me-3 lh-34 text-center pointer ls-3 w50 mb-3">{t('AdvertisementView1')}</span>
        </div>
      </div>
     {
                props.item.url ?
                <p className="fw-500 text-grey-600 lh-26 font-xssss w-100 ms-2 mb-0 mt-1">
                 {props.item.url}
                </p>
                :
              <></>

              } 
              
        <div className="card-body d-flex  p-0">
            <div
              className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss me-2"
            >
              <i className="feather-radio text-dark text-dark text-grey-600 btn-round-sm font-sm"></i>{" "}
              {t('AdvertisementView2')}
            </div>
          </div>
    </div>
    :<></>
    }
      
      </DialogContent>
    </Dialog>
  );
};

export default AdPreviewModal;
