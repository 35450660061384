import React, { createContext, useState, useEffect, useContext } from "react";
import { withCookies, useCookies } from "react-cookie";
import axios from "axios";

import { SnackbarContext } from './SnackbarContext';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { post } from "jquery";
// import { Toast } from "react-bootstrap";
export const ApiContext = createContext();

const ApiContextProvider = (props) => {

  const history = useHistory();
  const [ads, setAds] = useState([]);
  // const [userAds, setUserAds] = useState([]);
  // const [token, setUserToken] = useState(props.cookies.get("current-token"));
  const [token, setUserToken] = useState(localStorage.getItem('current-token'));
  const [profile, setProfile] = useState([]);
  // const [profiles, setProfiles] = useState([]);
  const [specificProfile, setSpecificProfile] = useState([]);
  const [editedProfile, setEditedProfile] = useState(null);
  // const [askList, setAskList] = useState([]);
  const [askData, setAskData] = useState([])
  const [askListFull, setAskListFull] = useState([]);
  const [cover, setCover] = useState([]);
  const [coverBack, setCoverBack] = useState([]);
  const [monopages, setMonopages] = useState([])
  const [monoposts, setMonoposts] = useState([])
  const [mymonoposts, setMyMonoposts] = useState([])
  const [monocomments, setMonocomments] = useState([])
  const [pageattribute, setPageattribute] = useState([])
  // const [currentPageId, setCurrentPageId] = useState([])
  // const [currentUserId, setCurrentUserId] = useState([])
  const [intpage, setIntpage] = useState([])
  const [postsforintpage, setPostsforintpage] = useState([])
  const [intpost, setIntpost] = useState([])
  const [intuser, setIntuser] = useState([])
  const [intcomment, setIntComment] = useState([])
  // const [intattribute, setIntAttribute] = useState([])
  const [postsforintuser, setPostsforintuser] = useState([])
  const [monopageposts, setMonopageposts] = useState([])
  const [postimg, setPostimg] = useState([])
  const [createdmonopost, setCreatedMonopost] = useState({ text: "", rating: "", reviewTo: "" })
  const [createdpurepost, setCreatedPurepost] = useState({ text: ""})
  const [friendrequest, setFriendRequest] = useState([])
  const [commentsforintpost, setCommentsforintpost] = useState([])
  const [createdcomment, setCreatedcomment] = useState({ text: "", post: "" })
  // const [followinguserpost, setFollowinguserpost] = useState([])
  const [followinguser, setFollowinguser] = useState([])
  const [followeduser, setFolloweduser] = useState([])
  // const [mutualfollowinguser, setMutualfollowinguser] = useState([])
  const [followingpage, setFollowingpage] = useState([])
  // const [owningpage, setOwningpage] = useState([])
  const [pagesforintattribute, setPagesforintattribute] = useState([])
  // const [affiliates, setAffiliates] = useState([])
  // const [isfollowingpage, setIsfollowingpage] = useState([])
  // const [uploadedfiles, setUploadedfiles] = useState([])
  // const [userintatt, setUserintatt] = useState()
  // const [mylikedposts, setMylikedposts] = useState()
  // const [likedposts, setLikedposts] = useState()
  // const [owningpageinfo, setOwningpageinfo] = useState([])
  // const [repostedmonopost, setRepostedmonopost] =useState([])
  // const [repostedmonopostcomments, setRepostedmonopostcomments] = useState([])
  // const [attja, setAttja] = useState([])
  // const [atten, setAtten] = useState([])
  const [adsforpage, setAdsforpage] = useState([])
  const [relatedPages, setRelatedPages] = useState([]);
  const [isFollowed, setIsFollowed] = useState([]);
  const [isOwned, setIsOwned] = useState([]);
  const [newProfiles, setNewProfiles] = useState([]);
  const [followCheck, setFollowCheck] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0); // 通知件数のstate
  
  
  const { pathname } = useLocation();
  const { t } = useTranslation();
  

  const { newSnack } = useContext(SnackbarContext);

  const setToken = (pToken) => {
    setUserToken(pToken);
  }

/// ログインしていれば常にprofileの値を取得する
  useEffect(() => {
    const getMyProfile = async () => {
      if (!token) return;
  
      try {
        const resmy = await axios.get(process.env.REACT_APP_API_URL + "/api/user/myprofile/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
  
        const profileData = resmy.data[0];
  
        if (profileData && profileData.birth === null) {
          newSnack("info", "プロフィールを記入してください。");
          setProfile(profileData);
          setEditedProfile({
            id: profileData.id,
            nickName: profileData.nickName,
            caption: profileData.caption,
            birthday: profileData.birth,
            sex: profileData.sex === null ? "0" : profileData.sex.toString(),
          });
          history.push('/account');
          return;
        }
  
  
        setProfile(profileData);
        setEditedProfile({
          id: profileData.id,
          nickName: profileData.nickName,
          caption: profileData.caption,
          birthday: profileData.birth,
          sex: profileData.sex === null ? "0" : profileData.sex.toString(),
        });
  
      } catch (err) {
        history.push('/login');
      }
    };
  
    getMyProfile();
  
  }, [token, profile.id, profile.userProfile]);
  

 /// レコメンドの初期値として10attributeを取得する関数

  const getAttribute = async (lan) => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/api/user/pageattribute/by-language/${lan}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      setPageattribute(res.data);
    } catch {
      console.log("error-getAttribute");
    }
  }


  /// 特定ページに紐づくattributeを取得する関数

  const getAttributesForMonoPage = async (pageId) => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/api/user/pageattribute/by-monopage/${pageId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log("error-getAttributesForMonoPage", error);
      return [];
    }
  };
  
  /// 文字列でattributeを検索する関数

  const searchAttributes = async (query, lan) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/user/pageattribute/search/`,
            {
                params: { q: query, lan: lan },
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        return res.data;
    } catch (error) {
        console.log("error-searchAttributes", error);
        return [];
    }
};

/// userがlikeしたページを取得する関数

const getLikedPosts = async (targetuserid) => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL +`/api/user/?user_id=${targetuserid}/likedposts`,{
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    // setLikedposts(res.data)
  } catch(error) {
    console.log("error-likedposts")
    console.log(error)
  }
}

/// userがフォローしているページを取得する関数

const getFollowingPage = async (count) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/followingpage/?count=${count}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching following pages", error);
    return [];
  }
};


/// ページを文字列で検索する関数

const searchPages = async (query) => {
  try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/monopage/search-pages/`, {
          params: { query: query },
          headers: {
              Authorization: `Token ${token}`,
          },
      });
      return res.data;
  } catch (error) {
      console.error("Error searching pages", error);
      return [];
  }
};



// const getOwningPage = async () => {
//   try {
//     const respage = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopage/owned-pages", {
//       headers: {
//         Authorization: `Token ${token}`,
//       },
//     });
//     respage && setOwningpage(respage);
//   } catch {
//     console.log("error-getOwningPage")
//   }
// }

  /// フォローしているユーザーを取得する関数

  const getFollowingUser = async (userId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/approval/Followinguser/${userId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
  
      if (res.data && res.data.length > 0) {
        setFollowinguser(res.data);
      }
    } catch (error) {
      console.log("error-getFollowingUser", error);
    }
  };
  
  /// フォローされているユーザーを取得する関数

  const getFollowedUser = async (userId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/approval/Followeduser/${userId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
  
      if (res.data && res.data.length > 0) {
        setFolloweduser(res.data);
      }
    } catch (error) {
      console.log("error-getFollowedUser", error);
    }
  };

  ///特定のユーザーに自分がフォローされているか、フォローしているか、相互フォローかを確認する関数

  const getFollowCheck = async (userId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/approval/followcheck/${userId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
  
      if (res.data) {
        return res.data;  // 修正: 結果を返す
      } else {
        return null;  // データがない場合は null を返す
      }
    } catch (error) {
      console.log("error-getFollowCheck", error);
      return null;  // エラーが発生した場合も null を返す
    }
  };
  

  

  // const getMutualFollowingUser = async () => {
  //   try {
  //     const resreqall = await axios.get(process.env.REACT_APP_API_URL + "/api/user/approval/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     const resprof = await axios.get(process.env.REACT_APP_API_URL + "/api/user/profile/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     const following = resreqall.data.filter((each) => {
  //       return each.approved === true
  //     });
  //     const a = following.map((follow) => {
  //       return follow.askTo
  //     })
  //     const b = following.map((follow) => {
  //       return follow.askFrom
  //     })
  //     const concat = [...a, ...b]
  //     const set = new Set(concat)
  //     const followinguser = [...set]
  //     const followinguserprof = resprof.data.filter((prof) => {
  //       return (followinguser.includes(prof.userProfile))
  //     })
  //     followinguserprof && setMutualfollowinguser(followinguserprof)
  //   } catch {
  //     console.log("error-getMutualFollowingUser");
  //   }
  // }

  // const getFriendRequest = async () => {
  //   try {

  //     const resreqall = await axios.get(process.env.REACT_APP_API_URL + "/api/user/approval/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     const requests = resreqall.data.filter((each) => {
  //       return each.approved === false
  //     })
  //     requests.data && setFriendRequest(requests)
  //     // requests.data && setFriendRequest(requests.filter((each) => {
  //     //   return each.askTo === Number(resprof.data[0].userProfile)
  //     // }))

  //   } catch {
  //     console.log("error-getFriendRequest");
  //   }
  // }

  const deleteFriendRequest = async (askFrom, askTo) => {
    const data = {
        askFrom: askFrom,
        askTo: askTo
    };

    const config = {
        headers: {
            Authorization: `Token ${token}`,
        }
    };

    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + "/api/user/approval/delete_request/", data, config);

        if (res.data && Array.isArray(res.data)) {
            const requests = res.data.filter((each) => each.approved === false);
            setFriendRequest(requests);
        }

        // getFollowingUserPosts();
        // getFriendRequest();
        getFollowingUser();
        // getMutualFollowingUser();
        newSnack("error", "フォローを解除しました");

    } catch (error) {
        console.log("Error deleting the friend request:", error);
        newSnack("error", "フォロー解除に失敗しました");
    }
}

const deleteFollowingPage = async (pageId) => {
  const config = {
      headers: {
          Authorization: `Token ${token}`,
      },
      params: { pageId: pageId }
  };

  try {
      const res = await axios.delete(process.env.REACT_APP_API_URL + "/api/user/unfollow-page/", config);
      newSnack("error", "フォローを解除しました");

      // ページ情報を再取得して状態を更新
      await getPageInfo(pageId);
  } catch (error) {
      console.log("Error deleting the friend request:", error);
      newSnack("error", "フォロー解除に失敗しました");
  }
};

  // const getProfile = async () => {
  //   axios.get(process.env.REACT_APP_API_URL + "/api/user/profile/", {
  //     headers: {
  //       Authorization: `Token ${token}`,
  //     },
  //   }).then((res) => {
  //     if (res.data) {
  //       setProfiles(res.data);          
  //     }
  //   })
  //   .catch((err) =>{
  //     console.log("error-getProfile",err);
  //   })
  // };

  /// ホーム画面用、新規ユーザーを10件取得する関数

  const getNewProfile = async () => {
    axios.get(process.env.REACT_APP_API_URL + "/api/user/profile/newprofiles/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    }).then((res) => {
      if (res.data) {
        setNewProfiles(res.data);          
      }
    })
    .catch((err) =>{
      console.log("error-getProfile",err);
    })
  };

  /// 他のユーザーを検索する関数

  const searchProfiles = async (query) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/profile/search/`,
        {
          params: { q: query },
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log("error-searchProfiles", error);
      return [];
    }
  };
  

  // const getMonoPages = async () => {
  //   try {
  //     const res = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopage/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     setMonopages(res.data)
  //   } catch {
  //     console.log("error-getMonoPages")
  //   }
  // }
  // const getMonoPosts = async () => {
  //   try {
  //     const res = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopost/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     setMonoposts(res.data);
  //   } catch {
  //     console.log("error-getMonoPosts");
  //   }
  // }

  // const getFollowingUserPosts = async () => {
  //   try {
  //     const respost = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopost/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     const resreqall = await axios.get(process.env.REACT_APP_API_URL + "/api/user/approval/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     const following = resreqall.data.filter((each) => {
  //       return each
  //     });
  //     const a = following.map((follow) => {
  //       return follow.askTo
  //     })
  //     const b = following.map((follow) => {
  //       return follow.askFrom
  //     })
  //     const concat = [...a, ...b]
  //     const set = new Set(concat)
  //     const followinguser = [...set]
  //     const post = respost.data.filter((post) => {
  //       return (followinguser.includes(post.userPost))
  //     })
  //     post && setFollowinguserpost(post)
  //   } catch {
  //     console.log("error-getFollowingUserPosts");
  //   }
  // }

  // const getMonoComments = async () => {
  //   try {
  //     const res = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monocomment/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     setMonocomments(res.data);
  //   } catch {
  //     console.log("error-getMonoComments");
  //   }
  // }

  // const getUserInterest = async () => {
  //   try {
  //     // console.log("GetUserInterest");
  //     const resintpage = await axios.get(
  //       process.env.REACT_APP_API_URL + "/api/user/userintpage/",
  //       {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //     const resintpost = await axios.get(
  //       process.env.REACT_APP_API_URL + "/api/user/userintpost/",
  //       {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //     const resintcomment = await axios.get(
  //       process.env.REACT_APP_API_URL + "/api/user/userintcomment/",
  //       {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //     const resintuser = await axios.get(
  //       process.env.REACT_APP_API_URL + "/api/user/userintuser/",
  //       {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //     const resintattribute = await axios.get(process.env.REACT_APP_API_URL + "/api/user/userintattribute/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     const respage = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopage/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     const respost = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopost/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     const resuser = await axios.get(process.env.REACT_APP_API_URL + "/api/user/profile/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     const rescomment = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monocomment/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     const resattribute = await axios.get(process.env.REACT_APP_API_URL + "/api/user/pageattribute/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     const resaf = await axios.get(process.env.REACT_APP_API_URL + "/api/user/affiliatelinks/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     // console.log("rescomment", rescomment.data);
  //     resintpage.data[0] &&
  //       setIntpage(respage.data.find((each) => {
  //         return each.id === Number(resintpage.data[0].intPageId)
  //       }));
  //     resintpost.data[0] &&
  //       setIntpost(respost.data.find((each) => {
  //         return each.id === Number(resintpost.data[0].intPostId)
  //       }));
  //     resintcomment.data[0] &&
  //       setIntComment(rescomment.data.find((each) => {
  //         return each.id === Number(resintcomment.data[0].intCommentId)
  //       }));
  //     resintuser.data[0] &&
  //       setIntuser(resuser.data.find((each) => {
  //         return each.userProfile === Number(resintuser.data[0].intUserId)
  //       }));
  //     resintattribute.data[0] &&
  //       setIntAttribute(resattribute.data.find((each) => {
  //         return each.id === Number(resintattribute.data[0].intAttributeId)
  //       }));
  //     resintpage.data[0] &&
  //       setPostsforintpage(respost.data.filter((each) => {
  //         return each.reviewTo === Number(resintpage.data[0].intPageId)
  //       }));
  //     resintuser.data[0] &&
  //       setPostsforintuser(respost.data.filter((each) => {
  //         return each.userPost === Number(resintuser.data[0].intUserId)
  //       }));
  //     resintpost.data[0] &&
  //       setCommentsforintpost(rescomment.data.filter((each) => {
  //         return each.post === Number(resintpost.data[0].intPostId)
  //       }));
  //     resintattribute.data[0] &&
  //       setPagesforintattribute(respage.data.filter((each) => {
  //         // console.log("each ", each);
  //         return each.attribute.includes(resintattribute.data[0].intAttributeId)
  //       }));
  //     resaf.data[0] &&
  //       setAffiliates(resaf.data[0])
  //     const tmpIntpage = respage.data.find((each) => {
  //       return each.id === Number(resintpage.data[0].intPageId)
  //     });
  //     tmpIntpage &&
  //       setAffiliates(resaf.data.find((each) => {
  //         return each.id === Number(tmpIntpage.affiliateId)
  //       }));

  //   } catch {
  //     console.log("error-getUserInterest");
  //   }
  // }

  /// プロフィールを新規作成する関数

  const createProfile = async () => {
    const createData = new FormData();
    editedProfile.nickName && createData.append("nickName", editedProfile.nickName);
    editedProfile.caption && createData.append("caption", editedProfile.caption);
    cover.name && createData.append("img", cover, cover.name);
    coverBack.name && createData.append("imgBackground", coverBack, coverBack.name);
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/api/user/profile/",
        createData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      setProfile(res.data);
      setEditedProfile({ id: res.data.id, nickName: res.data.nickName, caption: res.data.caption });
      alert("プロフィールを新規作成しました");
    } catch {
      console.log("error-createProfile");
    }
  };

  const DeleteAccountSubmit = async () => {
    const createData = new FormData();
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/api/user/deleteprofile/",
        createData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      alert("アカウント削除を申請しました");
    } catch {
      console.log("error-createProfile");
      alert("アカウント削除申請に失敗しました");
    }
  };
  
  /// Postを投稿する関数

  const createMonoPost = async () => {
    const createData = new FormData();
    createData.append("text", createdmonopost.text)
    createData.append("rating", createdmonopost.rating)
    createData.append("reviewTo", createdmonopost.reviewTo)
    postimg.name && createData.append("img", postimg, postimg.name);
    // console.log(createData)
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/api/user/monopost/",
        createData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      newSnack("success", "ポストが投稿されました。");
      const resMonoPosts = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopost/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return res.data; // 新しい投稿データを返す
    } catch {
      newSnack("error", "投稿が失敗しました。");
      console.log("error-createMonoPost")
      return null;
    }
  }

  /// ページに紐づかない投稿をする関数

  const createPurePost = async () => {
    const createData = new FormData();
    createData.append("text", createdpurepost.text)
    createData.append("purepost_flag", true)
    postimg.name && createData.append("img", postimg, postimg.name);
    // console.log(createData)
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/api/user/monopost/",
        createData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      newSnack("success", "ポストが投稿されました。");
      const resMonoPosts = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopost/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      // setMonoposts(resMonoPosts.data);
      // setPostsforintpage(resMonoPosts.data.filter((each) => {
      //   return each.reviewTo === Number(currentPageId)
      // }));
      // getFollowingUserPosts();
      // console.log("CreateMonopost", postsforintpage);
      // getUserInterest()
    } catch {
      newSnack("error", "投稿が失敗しました。");
      console.log("error-createPurePost")
    }
  }

  /// リポストする関数
  const createRepost = async (targetpost, userId) => {
    const createData = new FormData();
    targetpost.title && createData.append("title", targetpost.title)
    targetpost.text && createData.append("text", targetpost.text)
    targetpost.rating && createData.append("rating", targetpost.rating)
    targetpost.reviewTo && createData.append("reviewTo", targetpost.reviewTo)
    createData.append("reposting", true)
    createData.append("repostingFrom", targetpost.id)
    createData.append("repostingFromUser", targetpost.userPost)
    targetpost.img && createData.append("text", targetpost.text + '  【画像付き】')
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/api/user/monopost/",
        createData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      // getMonoPosts();
      // getFollowingUserPosts();
      // getUserInterest();

    } catch {
      console.log("error-createRepost")
    }
  }

    /// Purepostをリポストする関数
    const createPureRepost = async (targetpost, userId) => {
      const createData = new FormData();
      targetpost.title && createData.append("title", targetpost.title)
      targetpost.text && createData.append("text", targetpost.text)
      targetpost.rating && createData.append("rating", targetpost.rating)
      targetpost.reviewTo && createData.append("reviewTo", targetpost.reviewTo)
      createData.append("reposting", true)
      createData.append("repostingFrom", targetpost.id)
      createData.append("repostingFromUser", targetpost.userPost)
      targetpost.img && createData.append("text", targetpost.text + '  【画像付き】')
      createData.append("purepost_flag", true)
      try {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/api/user/monopost/",
          createData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        // getMonoPosts();
        // getFollowingUserPosts();
        // getUserInterest();
  
      } catch {
        console.log("error-createRepost")
      }
    }

/// コメントを作成する関数
const createMonoComment = async () => {
  const createData = new FormData();
  createData.append("text", createdcomment.text);
  createData.append("post", createdcomment.post);

  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/api/user/monocomment/",
      createData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }
    );
    return res.data; // 新しいコメントとプロフィール情報を含むデータを返す
  } catch (error) {
    console.log("error-createMonoComment", error);
    return null; // エラーが発生した場合はnullを返す
  }
};


  
  
  /// ポストを削除する関数

  const deleteMonopost = async (deletepostid) => {
    try {
      await axios.delete(
        process.env.REACT_APP_API_URL + `/api/user/monopost/${deletepostid}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      // setMonoposts(monoposts.filter((dev) => dev.id !== deletepostid));
      // setMyMonoposts(mymonoposts.filter((dev) => dev.id !== deletepostid));
      // setPostsforintpage(postsforintpage.filter((dev) => dev.id !== deletepostid));
      // getFollowingUserPosts();
      // getMonoPosts();
      // getUserInterest();
      newSnack("success", "削除しました。");
    } catch {
      newSnack("error", "削除が失敗しました。");
      console.log("error-deleteMonopost");
    };
  };
  
  ///　コメントを削除するかんすう
  const deleteComment = async (deletecommentid, onSuccess) => {
    try {
      await axios.delete(
        process.env.REACT_APP_API_URL + `/api/user/monocomment/${deletecommentid}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (onSuccess) {
        onSuccess(deletecommentid); // コールバック関数を呼び出す
      }
      newSnack("success", "削除しました。");
    } catch {
      newSnack("error", "削除が失敗しました。");
      console.log("error-deleteComment");
    }
  };
  
  

  /// 特定のAttributeに紐づくページを取得する関数

  const getPagesForIntAttribute = async (attId) => {
    try {
        const respage = await axios.get(process.env.REACT_APP_API_URL + `/api/user/pageattribute/${attId}/monopages/`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        return respage.data; // データを返す
    } catch {
        console.log("error-getPagesForIntAttribute");
        return []; // エラーの場合は空の配列を返す
    }
};

  /// 無限スクロールでAttributeを取得する関数

  const getAttributesWithScroll = async (lan, count) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/attributecc/`, {
            headers: {
                Authorization: `Token ${token}`,
            },
            params: {
                lan: lan,
                count: count,
            },
        });

        // 取得した属性データを返す
        return res.data.res;
    } catch (error) {
        console.log("error-getAttributesWithScroll", error);
        return [];
    }
};

///　特定のユーザーのプロフィール画像を取得する関数

const getSpecificProfile = async (userid) => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + `/api/user/profile/${userid}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    setSpecificProfile(res.data);
    return specificProfile.img
  } catch {
    console.log("error-getSpecificProfile")
  }
}

///　特定のユーザーのプロフィールを取得する関数

const getSpecificProfileAll = async (userid) => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + `/api/user/profile/detail/${userid}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    setSpecificProfile(res.data);
    return specificProfile
  } catch {
    console.log("error-getSpecificProfile")
  }
}

///　未読通知の総件数と、新しいもの上から五件を取得する関数

const getNotification = async () => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + `/api/user/notifications/unread/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    if (res.data) {
      setNotificationCount(res.data.total_unread_count);  // 通知件数をセット
      setNotifications(res.data.notifications)
    }
    return res.data
  } catch {
    console.log("getNotification")
  }
}


  // 通知を既読にして通知一覧を更新する関数
  const markNotificationAsRead = async (notificationId) => {
    try {
      // 通知を既読に更新
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/user/notifications/${notificationId}/`,
        { read: true },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      // 新しい通知一覧を取得してコンテキストを更新
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/notifications/unread/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setNotifications(res.data);
    } catch (error) {
      console.error("Failed to update notification:", error);
      newSnack("error", t('Failed to update notification'));
    }
  };



  // const deleteProfile = async () => {
  //   try {
  //     await axios.delete(
  //       process.env.REACT_APP_API_URL + `/api/user/profile/${profile.id}/`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );
  //     setProfiles(profiles.filter((dev) => dev.id !== profile.id));
  //     setProfile([]);
  //     setEditedProfile({ id: "", nickName: "", caption: "" });
  //     setCover([]);
  //     setCoverBack([]);
  //     setAskList([]);
  //     alert("プロフィールを削除しました");
  //   } catch {
  //     console.log("error-deleteProfile");
  //   }
  // };

  /// 自分のプロフィールの編集結果を保存する関数

  const editProfile = async (file = null) => {
    const editData = new FormData();
    console.log("editProfile", editedProfile.sex);
    editedProfile.nickName && editData.append("nickName", editedProfile.nickName);
    editedProfile.birthday && editData.append("birth", editedProfile.birthday);
    editedProfile.sex && editData.append("sex", editedProfile.sex);
    editedProfile.caption && editData.append("caption", editedProfile.caption);
    if(!file) cover.name && editData.append("img", cover, cover.name);
    else file.name && editData.append("img", file, file.name);
    coverBack.name && editData.append("imgBackground", coverBack, coverBack.name);
    // if (!editedProfile.sex) {
    //   setEditedProfile({...editedProfile, sex: 0});
    //   editData.append("sex", 0);
    // }
    //console.log(editProfile.birth);

    try {
      const res = await axios.put(
        process.env.REACT_APP_API_URL + `/api/user/profile/${profile.id}/`,
        editData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      // console.log(res.data);
      setProfile(res.data);
      // getProfile();
      newSnack("success", "変更を保存しました");
    } catch {
      console.log("error-editProfile");
    }
  };

  /// ポストにいいねする関数

  const likePost = async (postdata) => {
    const editData = new FormData();
    editData.append("text", postdata.text);
    postdata.reviewTo && editData.append("reviewTo", postdata.reviewTo);
    postdata.rating && editData.append("rating", postdata.rating);
    postdata.reposting && editData.append("reposting", postdata.reposting);
    postdata.repostingFrom && editData.append("repostingFrom", postdata.repostingFrom);
    postdata.repostingFromUser && editData.append("repostingFromUser", postdata.repostingFromUser);
    
    const concat = [...postdata.liked, profile.userProfile]
    const set = new Set(concat)
    const likes = [...set].map(Number);
    // const compareFunc = (a,b) => {
    //   return a - b;
    // }
    if (Array.isArray(likes)) {
      likes.forEach((v, i) => {
        editData.append("liked", v)
      })
    } else {
      editData.append("liked", likes)
    }
    try {
      const res = await axios.put(
        process.env.REACT_APP_API_URL + `/api/user/monopost/${postdata.id}/`,
        editData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      // getMonoPosts();
      // getFollowingUserPosts();
      // getMonoPosts();
      // getFollowingUserPosts();
      // getUserInterest();
    } catch {
      console.log("error-likePost")
    }
  }

    /// Pureポストにいいねする関数

    const likePurePost = async (postdata) => {
      const editData = new FormData();
      editData.append("text", postdata.text);
      postdata.reviewTo && editData.append("reviewTo", postdata.reviewTo);
      postdata.rating && editData.append("rating", postdata.rating);
      postdata.reposting && editData.append("reposting", postdata.reposting);
      postdata.repostingFrom && editData.append("repostingFrom", postdata.repostingFrom);
      postdata.repostingFromUser && editData.append("repostingFromUser", postdata.repostingFromUser);
      editData.append("purepost_flag", true)
      const concat = [...postdata.liked, profile.userProfile]
      const set = new Set(concat)
      const likes = [...set].map(Number);
      // const compareFunc = (a,b) => {
      //   return a - b;
      // }
      if (Array.isArray(likes)) {
        likes.forEach((v, i) => {
          editData.append("liked", v)
        })
      } else {
        editData.append("liked", likes)
      }
      try {
        const res = await axios.put(
          process.env.REACT_APP_API_URL + `/api/user/monopost/${postdata.id}/`,
          editData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        )
        // getMonoPosts();
        // getFollowingUserPosts();
        // getMonoPosts();
        // getFollowingUserPosts();
        // getUserInterest();
      } catch {
        console.log("error-likePost")
      }
    }

  /// 特定のPostに紐づくコメントを取得する関数
  
  const getMonoCommentsForPost = async (postId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/monocomment/post-comments/?post_id=${postId}`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        return res.data;
    } catch (error) {
        console.log("error-getMonoCommentsForPost", error);
    }
};

/// 自分がUploadしたファイルを取得する関数
const getUploadedFilesForUser = async (userId) => {
  try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/uploadedfiles/?user_id=${userId}`, {
          headers: {
              Authorization: `Token ${token}`,
          },
      });
      return res.data;
  } catch (error) {
      console.log("error-getUploadedFilesForUser", error);
  }
};


  /// Likeを取り消す関数

  const DeletelikePost = async (postdata) => {
    const editData = new FormData();
    postdata.text && editData.append("text", postdata.text);
    postdata.reviewTo && editData.append("reviewTo", postdata.reviewTo);
    postdata.rating && editData.append("rating", postdata.rating);
    postdata.reposting && editData.append("reposting", postdata.reposting);
    postdata.repostingFrom && editData.append("repostingFrom", postdata.repostingFrom);
    postdata.repostingFromUser && editData.append("repostingFromUser", postdata.repostingFromUser);
    const likes = postdata.liked.filter(n => n !== profile.userProfile)
    console.log(likes)
    // const compareFunc = (a,b) => {
    //   return a - b;
    // }
    if (Array.isArray(likes)) {
      likes.forEach((v, i) => {
        editData.append("liked", v)
      })
    } else {
      editData.append("liked", likes)
    }
    try {
      const res = await axios.put(
        process.env.REACT_APP_API_URL + `/api/user/monopost/${postdata.id}/`,
        editData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      // getMonoPosts();
      // getFollowingUserPosts();
      // getMonoPosts();
      // getFollowingUserPosts();
      // getUserInterest();
    } catch {
      console.log("error-likePost")
    }
  }

  ///フォローする関数

  const newRequestFriend = async (askData) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + `/api/user/approval/`,
        askData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      setAskListFull([...askListFull, res.data]);
      // getFollowingUserPosts();
      // getFriendRequest();
      // getFollowingUser();
      // getMutualFollowingUser();
    } catch {
      console.log("error-newRequestFriend");
    }
  };

  /// ポストをコメントしたことがあるか、Repostしたことがあるか、コメントをしたことがあるか確認する関数

  const checkPostInteractions = async (postId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/post-interactions/${postId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error checking post interactions", error);
      return {
        is_liked: false,
        is_reposted: false,
        is_commented: false,
      };
    }
  };

  /// Repostを取り消す関数

  const deleteRepost = async (postId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/user/delete-repost/${postId}/delete_repost/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
    } catch (error) {
      console.log("error-deleteRepost", error);
      throw error;
    }
  };
  



  // const newUserIntPage = async (pageId, userId) => {
  //   try {
  //     setCurrentPageId(pageId);
  //     localStorage.setItem('userIntPageId', pageId)
  //     const resviewcount = await axios.get(
  //       process.env.REACT_APP_API_URL + `/api/user/record_page_view/${userId}/${pageId}/`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     )
  //     if (monopages.length == 0) {
  //       const respage = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopage/", {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //       setIntpage(respage.data.find((each) => {
  //         return each.id === Number(pageId)
  //       }));
  //       // console.log("monopages", monopages);
  //     } else {
  //       setIntpage(monopages.find((each) => {
  //         return each.id === Number(pageId)
  //       }));

  //     }
  //     if (monoposts.length == 0) {
  //       const respost = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopost/", {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //       setPostsforintpage(respost.data.filter((each) => {
  //         return each.reviewTo === Number(pageId)
  //       }))
  //       // console.log("monoposts", monoposts);
  //     } else {
  //       setPostsforintpage(monoposts.filter((each) => {
  //         return each.reviewTo === Number(pageId)
  //       }))

  //     }
  //   } catch {
  //     console.log("error-newUserIntPage");
  //   }
  // }

  /// 特定ページの情報を取得する関数

  const getPageInfo = async (pageId) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/user/monopage/get-page-info/`, 
            {
                params: { pageId },
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );

        if (res.status === 200) {
            setIntpage(res.data);
            // setIsFollowed(res.data.is_followed);
            // setIsOwned(res.data.is_owned);
        }
    } catch (error) {
        console.error("Error fetching page info:", error);
    }
};

/// 特定ページを所有しているか判定する関数
const pageCheck = async (userId, pageId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/user/monopage/check-user-page-status/`, 
      {
        params: { userId, pageId },
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );

    if (res.status === 200) {
      setIsFollowed(res.data.is_followed);
      setIsOwned(res.data.is_owned);
    }
  } catch (error) {
    console.error("Error checking page status:", error);
  }
};

  
/// ページに紐づくポストを無限スクロールする関数

const newUserIntPageWithScroll = async (pageId, count) => {
  try {
      // setCurrentPageId(pageId);
      await getPageInfo(pageId);

      const respost = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/monopostcc/`,
          {
              params: { pageId, count },
              headers: {
                  Authorization: `Token ${token}`,
              },
          }
      );

      return respost.data;
  } catch {
      console.log("error-newUserIntPage");
  }
};

/// ページを購入する際、ページのIdをIntpageというモデル（いにしえ）に記録する関数
  const setIntPageIdOnly = async (pageId) => {
    try {
      // setCurrentPageId(pageId);
      localStorage.setItem('userIntPageId', pageId);
  
      // idだけをセット
      setIntpage({ id: pageId });
  
      // 必要であれば、ページビューの記録だけを行う
      await axios.get(
        process.env.REACT_APP_API_URL + `/api/user/record_page_view/${profile.userProfile}/${pageId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
    } catch {
      console.log("error-setIntPageIdOnly");
    }
  };

  /// ユーザー検索画面でプロフィールを無限スクロールする関数

  const profilesWithScroll = async (count) => {
    try {
      const respost = await axios.get(process.env.REACT_APP_API_URL + "/api/user/profilecc/?&count="+count, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return respost.data;
    } catch {
      console.log("error-newUserIntPage");
    }
  }

   // フォローしているユーザーを無限スクロールで取得する関数
   const fetchFollowingUsersWithScroll = async (userId, count) => {
    try {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/api/user/followinguser/${userId}/?count=${count}`, {
            headers: {
                Authorization: `Token ${token}`,  // トークンをヘッダーに含める
            },
        });
        return res.data;
    } catch (error) {
        console.log("Error fetching following users with scroll:", error);
        return [];
    }
};

// フォローされているユーザーを無限スクロールで取得する関数
const fetchFollowedUsersWithScroll = async (userId, count) => {
    try {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/api/user/followeduser/${userId}/?count=${count}`, {
            headers: {
                Authorization: `Token ${token}`,  // トークンをヘッダーに含める
            },
        });
        return res.data;
    } catch (error) {
        console.log("Error fetching followed users with scroll:", error);
        return [];
    }
};

  // フォロワー数とフォローしているユーザー数を取得
  const getFollowerFollowingCounts = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/follower_following_counts/${userId}/`, {
        headers: {
          Authorization: `Token ${token}`,  // 認証が必要な場合のみ
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching follower and following counts:", error);
      return { following_count: 0, followed_count: 0 };
    }
  };
  

  /// Home画面用、自分がフォローしているユーザーの投稿と、自分の投稿を合わせて無限スクロールする関数
  const postWithScroll = async (count) => {
    try {
        const respost = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/postcc/`, {
            params: { count },
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        return respost.data;
    } catch {
        console.log("error-postWithScroll");
    }
  };

  /// 特定ユーザーの投稿を無限スクロールする関数
  const userpostWithScroll = async (count, user_id) => {
    try {
      const respost = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/userpostcc/?count=${count}&user_id=${user_id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return respost.data;
    } catch {
      console.log("error-userpostWithScroll");
    }
  };

  /// 自分がLikeしたポストを無限スクロールする関数
  const fetchLikedPostsWithScroll = async (count) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/likedpostscc/likedposts/?count=${count}`, {
        headers: {
          Authorization: `Token ${token}`,  // トークンをヘッダーに含める
        },
      });
      return res.data.res;  // データ配列だけを返す
    } catch (error) {
      console.log("error-fetchLikedPostsWithScroll", error);
      return [];
    }
  };

  

  /// レコメンド画面用、日本語ページを無限スクロールする関数
  const pageWithScrollJA = async (count) => {
    try {
      const respage = await axios.get(process.env.REACT_APP_API_URL + "/api/user/pageccja/?&count=" +count, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return respage.data
    } catch {
      console.log("error-newPage");
    }
  }

  /// レコメンド画面用、英語ページを無限スクロールする関数
  const pageWithScrollEN = async (count) => {
    try {
      const respage = await axios.get(process.env.REACT_APP_API_URL + "/api/user/pageccen/?&count=" +count, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return respage.data
    } catch {
      console.log("error-newPage");
    }
  }

  /// 自分が所有しているページを無限スクロールする関数
  const owningPageWithScroll = async (count) => {
    try {
      const respage = await axios.get(process.env.REACT_APP_API_URL + "/api/user/owningpagecc/?&count=" +count, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      return respage.data
    } catch {
      console.log("error-newOwningPage");
    }
  }

  /// 自分が所有しているページを検索する関数
  const searchOwnedPages = async (query) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/monopage/search-owned-pages/`, {
        params: { query },
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log("error-searchOwnedPages", error);
      return [];
    }
  };

  // const newUserIntPost = async (interestData) => {
  //   try {
  //     const resintpost = await axios.post(
  //       process.env.REACT_APP_API_URL + "/api/user/userintpost/",
  //       interestData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     )
  //     const rescomment = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monocomment/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     const respost = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopost/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     resintpost.data[0] && setIntpost(respost.data.find((each) => {
  //       return each.id === Number(resintpost.data[0].intPostId)
  //     }));
  //     resintpost.data[0] && setCommentsforintpost(rescomment.data.filter((each) => {
  //       return each.post === Number(resintpost.data[0].intPostId)
  //     }))
  //   } catch {
  //     console.log("error-newUserIntPost");
  //   }
  // }

  /// 特定のユーザー情報を取得する関数
  const newUserIntUser = async (intUserId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/profile/get-user-profile/`, {
        params: { user_id: intUserId },
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setIntuser(res.data);
    } catch (err) {
      console.log("error-newUserIntUser");
      console.log(err);
    }
  };


  // const newUserIntUser = async (userId) => {
  //   try {
  //     setCurrentUserId(userId);
  //     localStorage.setItem("userIntUserId", userId)
  //     if (profiles.length == 0) {
  //       const resprofile = await axios.get(process.env.REACT_APP_API_URL + "/api/user/profile/",
  //       {
  //         headers: {
  //           Authorization: `Token ${token}`,
  //         },
  //       })
  //       setIntuser(resprofile.data.find((each) => {
  //         return each.userProfile === Number(userId)
  //       }));
  //     } else {
  //       setInt
  //     }
  //   }
  // }

  // const newUserIntAttribute = async (selectedAttributeId) => {
  //   try {
  //     // console.log(interestData);
  //     // const resintattribute = await axios.post(
  //     //   process.env.REACT_APP_API_URL + "/api/user/userintattribute/",
  //     //   interestData,
  //     //   {
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //       Authorization: `Token ${token}`,
  //     //     },
  //     //   }
  //     // )
  //     const respage = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monopage/", {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //     // const resattribute = await axios.get(process.env.REACT_APP_API_URL + "/api/user/pageattribute/", {
  //     //   headers: {
  //     //     Authorization: `Token ${token}`,
  //     //   },
  //     // })
  //     // resintattribute.data[0] &&
  //     //   setIntAttribute(resattribute.data.find((each) => {
  //     //     return each.id === Number(resintattribute.data[0].intAttributeId)
  //     //   }));
  //     // resintattribute.data[0] &&
  //     setPagesforintattribute(respage.data.filter((each) => {
  //       return each.attribute.includes(selectedAttributeId)
  //     }));
  //     setUserintatt(selectedAttributeId)
  //   } catch {
  //     console.log("error-newIntAttribute");
  //   }
  // }

  // const deleteUserIntAttribute = () => {
  //  setPagesforintattribute([])
  //  setUserintatt()
  // }

  /// ページをフォローする関数
  const followPage = async (id) => {
    try {
        const createData = { pageId: id };
        const res = await axios.post(
            process.env.REACT_APP_API_URL + "/api/user/followingpage/",
            createData,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        );
        newSnack("info", t('Mono1'));

        // ページ情報を再取得して状態を更新
        await getPageInfo(id);
    } catch (error) {
        if (error.response && error.response.data && error.response.data.detail) {
            newSnack("error", error.response.data.detail);
        } else {
            console.log("error-followPage", error.response ? error.response.data : error.message);
            newSnack("error", t('Mono31'));
        }
    }
};

  /// 特定ページ用の広告を取得する関数

  const getAdsforpage = async (id) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/api/user/advertisements-for-monopage/${id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      setAdsforpage(res.data)
    } catch(err) {
      console.log("error-getAdsforpage")
      console.log(err)
    }
  }

  /// ページを購入する関数
  const ownPage = async (id) => {
    const createData = new FormData();
    createData.append("encrypted_pageId", id)
    axios.post(
      process.env.REACT_APP_API_URL + "/api/user/owningpage/",
      createData, 
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then(res => {
      // newUserIntPage(res.data.pageId);
      // getOwningPage()
    }).catch ( err=>{
      console.log("error-ownPage", err)
    })
  }


  // const getOwingPageInfo = async (id) => {
  //   try {
  //     const res = await axios.get(
  //       process.env.REACT_APP_API_URL + `/api/user/view_page/${id}/`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );
  //     setOwningpageinfo(res.data)
  //   } catch(err) {
  //     console.log("error-getOwingPageInfo")
  //     console.log(err)
  //   }
  // }


  // const changeApprovalRequest = async (uploadDataAsk, ask) => {
  //   try {
  //     const res = await axios.put(
  //       process.env.REACT_APP_API_URL + `/api/user/approval/${ask.id}/`,
  //       uploadDataAsk,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );
  //     setAskList(askList.map((item) => (item.id === ask.id ? res.data : item)));

  //     const newDataAsk = new FormData();
  //     newDataAsk.append("askTo", ask.askFrom);
  //     newDataAsk.append("approved", true);

  //     const newDataAskPut = new FormData();
  //     newDataAskPut.append("askTo", ask.askFrom);
  //     newDataAskPut.append("askFrom", ask.askTo);
  //     newDataAskPut.append("approved", true);

  //     const resp = askListFull.filter((item) => {
  //       return item.askFrom === profile.userProfile && item.askTo === ask.askFrom;
  //     });

  //     !resp[0]
  //       ? await axios.post(
  //         process.env.REACT_APP_API_URL + `/api/user/approval/`,
  //         newDataAsk,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Token ${token}`,
  //           },
  //         }
  //       )
  //       : await axios.put(
  //         process.env.REACT_APP_API_URL + `/api/user/approval/${resp[0].id}/`,
  //         newDataAskPut,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Token ${token}`,
  //           },
  //         }
  //       );
  //   } catch {
  //     console.log("error-changeApprovalRequest");
  //   }
  // };

  // const getAds = async () => {
  //   try {
  //     const res = await axios.get(
  //       process.env.REACT_APP_API_URL + `/api/user/advertisement/`,
  //       {
  //         headers: {
  //           Authorization: `Token ${token}`
  //         }
  //       }
  //     )
  //     const resdata = res.data.filter((item) => { return item.del_flag == 0 && item.allowed });
  //     setAds(resdata);
  //   } catch {
  //     console.log("error-getAds");
  //   }
  // }

  /// 広告の閲覧カウントを更新する関数
  const addAdsCnt = async (id) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + `/api/user/updateAds/`,
        { updateCnt: 1, adsId: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const selected = ads.filter((item) => item.id === id);
      if (selected[0].cnt == selected[0].target - 1) {
        console.log("delflag");
        await axios.post(
          process.env.REACT_APP_API_URL + `/api/user/updateAds/`,
          { updateDelFlag: 1, adsId: id, updateCnt: 0 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        const newAds = ads.filter((item) => item.id !== id);
        setAds(newAds);
      } else if (selected[0].cnt > selected[0].target - 1) {
        console.log("delflag");
        await axios.post(
          process.env.REACT_APP_API_URL + `/api/user/updateAds/`,
          { updateDelFlag: 1, adsId: id, updateCnt: 0 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        const newAds = ads.filter((item) => item.id !== id);
        setAds(newAds);
      }
      // setAds((prev) => {
      //   const newAds = prev;
      //   newAds.map((item) => {
      //     if (item.id == id) item.cnt ++;
      //     return item;
      //   })
      //   return newAds;
      // })
    } catch {
      console.log("error-addAdsCnt");
    }
  }

  /// 広告キャンペーンを投稿する関数
  const allowAds = async (id) => {
    axios.post(
      process.env.REACT_APP_API_URL + `/api/user/allowAds/`,
      {adsId: id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then(res => {
      newSnack("success", "広告キャンペーンが登録されました。");
      setAds((prev) => {
        const newState = prev;
        newState.push(res.data);
        return newState;
      })
    }).catch((err)=>{
      console.log("error-allowAds", err);
    });
  }
  const postAds = async (data) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + `/api/user/advertisement/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      axios.post(
        process.env.REACT_APP_API_URL + "/api/user/post-ads-stripe/",
        {
            SITE_URL : window.location.href.split('?')[0],
            data: res.data
        },
        {
            headers: { "Content-Type": "application/json" },
        }
      ).then(respon => {
          window.location.replace(respon.data.goTo);
      }).catch(err => {
          console.log(err);
      });

      // console.log(res.data);
      // setProfile(res.data);
      // getProfile();
    } catch {
      console.log("error-postAds");
    }
  }



//   const getRepostedMonoPosts = async (id) =>{
//   try {
//     const res = await axios.get(process.env.REACT_APP_API_URL + `/api/user/monopost/${id}`, {
//       headers: {
//         Authorization: `Token ${token}`,
//       },
//     })
//     setRepostedmonopost(res.data);
//     if (monocomments.length==0) {
//       const rescomment = await axios.get(process.env.REACT_APP_API_URL + "/api/user/monocomment/", {
//         headers: {
//           Authorization: `Token ${token}`,
//         },
//       })
//       rescomment.data[0] &&setRepostedmonopostcomments(rescomment.data.filter((each) => {
//         return each.post === Number(id)
//       }))

//     } else {
//       setRepostedmonopostcomments(monocomments.filter
//         ((each) => {
//           return each.post === Number(id)
//         }))
//     }
//   } catch {
//     console.log("error-getMonoPosts");
//   }
// }

/// 関連ページを取得する関数

const fetchRelatedMonoPages = async (pageid) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `/api/user/relatedmonopage/${pageid}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    setRelatedPages(response.data);

  } catch(err) {
    console.log("error-fetchRelatedMonoPages")
    console.log(err)
    
  }
}

// 通知設定を取得するAPI
const getUserNotificationSettings = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/notification-settings/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.error("Error fetching notification settings", err);
  }
};

// 通知設定を更新するAPI
const updateUserNotificationSettings = async (updatedSettings) => {
  try {
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/notification-settings/update_notification_settings/`, updatedSettings, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.error("Error updating notification settings", err);
  }
};

// 自分のMonoPageを無限スクロールで取得する関数
const fetchUserMonoPagesWithFiles = async (userId, count) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}/monopages-with-files/?count=${count}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching user mono pages with files", error);
    return [];
  }
};

  

  return (
    <ApiContext.Provider
      value={{
        setToken,
        askData,
        setAskData,
        profile,
        // profiles,
        specificProfile,
        setSpecificProfile,
        cover,
        setCover,
        friendrequest,
        setFriendRequest,
        monopages,
        setMonopages,
        monoposts,
        setMonoposts,
        mymonoposts,
        setMyMonoposts,
        monocomments,
        setMonocomments,
        postimg,
        setPostimg,
        createdmonopost,
        setCreatedMonopost,
        deleteComment,
        coverBack,
        setCoverBack,
        monopageposts,
        intpage,
        setIntpage,
        intpost,
        setIntpost,
        intuser,
        setIntuser,
        postsforintpage,
        setPostsforintpage,
        postsforintuser,
        setPostsforintuser,
        commentsforintpost,
        setCommentsforintpost,
        createMonoComment,
        setCreatedcomment,
        createdcomment,
        followinguser,
        intcomment,
        setIntComment,
        followPage,
        // affiliates,
        // isfollowingpage,
        DeletelikePost,
        followeduser,
        // getFollowingUserPosts,
        getFollowedUser,
        getFollowingUser,
        setMonopageposts,
        pageWithScrollJA,
        pageWithScrollEN,
        // askList,
        askListFull,
        newRequestFriend,
        createProfile,
        editProfile,
        // deleteProfile,
        // changeApprovalRequest,
        editedProfile,
        setEditedProfile,
        createMonoPost,
        deleteMonopost,
        DeleteAccountSubmit,
        getSpecificProfile,
        // newUserIntPage,
        newUserIntPageWithScroll,
        profilesWithScroll,
        postWithScroll,
        // newUserIntPost,
        newUserIntUser,
        // getUserInterest,
        // newUserIntAttribute,
        // getProfile,
        // getMonoComments,
        // getMonoPages,
        getPagesForIntAttribute,
        // getMonoPosts,
        // getFriendRequest,
        // followinguserpost,
        // followinguser,
        // mutualfollowinguser,
        likePost,
        createRepost,
        followingpage,
        // owningpage,
        pageattribute,
        pagesforintattribute,
        ownPage,
        postAds,
        // getAds,
        ads,
        setAds,
        addAdsCnt,
        allowAds,
        // uploadedfiles,
        // userintatt,
        // mylikedposts,
        getLikedPosts,
        likePost,
        // deleteUserIntAttribute,
        // getOwingPageInfo,
        // getOwningPage,
        // owningpageinfo,
        owningPageWithScroll,
        // getRepostedMonoPosts,
        // repostedmonopost,
        // repostedmonopostcomments,
        // atten,
        // attja,
        getAdsforpage,
        adsforpage,
        deleteFriendRequest,
        deleteFollowingPage,
        createPurePost,
        createdpurepost,
        setCreatedPurepost,
        userpostWithScroll,
        fetchRelatedMonoPages,
        relatedPages,
        getFollowingPage,
        setIntPageIdOnly,
        isFollowed,
        isOwned,
        getPageInfo,
        getMonoCommentsForPost,
        newProfiles,
        getNewProfile,
        getUploadedFilesForUser,
        getFollowCheck,
        followCheck,
        searchProfiles,
        searchPages,
        getAttribute,
        getAttributesWithScroll,
        searchAttributes,
        getAttributesForMonoPage,
        pageCheck,
        setIsFollowed,
        searchOwnedPages,
        fetchLikedPostsWithScroll,
        fetchFollowedUsersWithScroll, 
        fetchFollowingUsersWithScroll,
        getFollowerFollowingCounts,
        checkPostInteractions,
        deleteRepost,
        createPureRepost,
        likePurePost,
        getNotification,
        notificationCount,
        notifications,
        markNotificationAsRead,
        getSpecificProfileAll,
        getUserNotificationSettings,
        updateUserNotificationSettings,
        fetchUserMonoPagesWithFiles
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export default withCookies(ApiContextProvider);