import React from "react";
import { Link, useLocation } from "react-router-dom";
// import { useState } from 'react';
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import { useTranslation } from 'react-i18next';

const Appfooter = () => {
  const { profile } = useContext(ApiContext);
  const location = useLocation(); 
  const isHome = location.pathname === "/home";
  const isRecommend = location.pathname === "/recommend";
  const isRecommendUser = location.pathname === "/recommenduser";
  const isOwningPages = location.pathname === "/owningpages";
  const isUserPage = location.pathname === "/userpage";
  const { t } = useTranslation();
  

  return (
    <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
      <Link to="/home" className="nav-content-bttn nav-center" style={isHome ? { borderBottom: "2px solid white" } : {}}>
        <i className="feather-server"></i>
      </Link>
      <Link to="/recommenduser" className="nav-content-bttn" style={isRecommendUser ? { borderBottom: "2px solid white" } : {}}>
        <i className="feather-users"></i>
      </Link>
      {/* <Link to="/recommend" className="nav-content-bttn" data-tab="chats" style={isRecommend ? { borderBottom: "2px solid white" } : {}}>
        <i className="feather-trending-up"></i>
      </Link> */}
      <Link to="/recommend">
      <img src="/assets/images/tatami_logo_green_4.png" alt="logo" className={isRecommend ? "btn-round-lg p-0 bg-white-pink shadow-md border-0":"btn-round-lg p-0 bg-grey shadow-md border-0"}/>
      </Link>
      <Link to="/owningpages" className="nav-content-bttn" style={isOwningPages ? { borderBottom: "2px solid white" } : {}}>
        <i className="feather-archive"></i>
      </Link>
      <Link to="/userpage" className="nav-content-bttn" style={isUserPage ? { borderBottom: "2px solid white" } : {}}>
        {profile.img ? (
          <img
            src={profile.img}
            alt="user"
            className="w40 shadow-xss rounded-circle"
          />
        ) : (
          <img
            src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
            alt="user"
            className="w40 shadow-xss rounded-circle"
          />
        )}
      </Link>
    </div>
  );
};

export default Appfooter;
