import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const FollowingPage = ({ monopage }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const toMonopage = () => {
        history.push("/mono/" + monopage.pageId.id);
    };

    const imageUrl = `https://ow6r85j5w096clt.site/media/image/background_1.jpg`;

    return (
        <div className="pointer">
            <div className="card w-30 shadow-xss rounded-xxl border-0 pt-3 ps-3 pe-3 pb-2 me-2 ms-2 mb-2">
                <div className="card-body p-0 d-flex pointer" onClick={toMonopage}>
                    {monopage.pageId.img ? (
                        <figure className="avatar me-3">
                            <img src={monopage.pageId.img} alt="avatar" className="shadow-sm rounded-3 w45" />
                        </figure>
                    ) : (
                        <figure className="avatar me-3">
                            <img src={imageUrl} alt="avatar" className="shadow-sm rounded-3 w45" />
                        </figure>
                    )}
                    <h4 className="fw-700 text-grey-900 font-xssss">
                        {monopage.pageId.title}
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                            {monopage.pageId.created_on}
                        </span>
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default FollowingPage;
