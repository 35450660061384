import React, { useState, useContext, useEffect } from "react";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AdvertisementView = (props) => {

  const history = useHistory();
  const { addAdsCnt } = useContext(ApiContext);
  

  const toAdsDetail = () => {
    if (props.item.type == 1) addAdsCnt(props.item.id);
    if (props.item.url == null) {
      return;
    }
    window.location = props.item.url;
  }

  useEffect(() => {
  	if (props.item.type == 0) addAdsCnt(props.item.id);
  }, [])
  const { t } = useTranslation();

  return (
    <div>
      {props.item && props.profile ?
      <div className="card w-100 shadow-xss rounded-xxl border-light-md ps-3 pe-3 pt-3 pb-2 mb-1">
      <div className="relative">
        <div className="card-body p-0 d-flex">
          <div>
            <div className="card-body p-0 d-flex">
              {
                props.profile.img ?
                <figure className="avatar me-3">
                <img
                  src={props.profile.img.slice(0,4)==="http" ? props.profile.img :process.env.REACT_APP_API_URL + props.profile.img}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                />
              </figure>:
              <></>
              }
              
              <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                {" "}
                {props.profile.nickName}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {props.item.created}
                </span>
              </h4>
            </div>

            <div className="card-body p-0 me-lg-5">
              <p className="fw-600 text-grey-600 lh-26 font-xssss w-100 mb-2">
                {props.item.content}
              </p>
            </div>
          </div>

          <div className="card-body p-0 mb-1 d-flex"></div>
        </div>

        <div className="card shadow-xss rounded-xxl border-light-md pt-2 pb-2 pe-2 ps-2 mb-0">
          <div className="row ps-3 pe-3">
            <div className="col-sm-12 p-1 me-2 ms-0 d-flex  justify-content-center">
              {
                props.item.img ?
                <img
                className="rounded-3 pointer"
                alt="post"
                style={{maxHeight: "400px", maxWidth: "100%"}}
                src={props.item.img.slice(0,4)==="http" ? props.item.img :process.env.REACT_APP_API_URL + props.item.img}
                onClick={toAdsDetail}
              />:
              <></>

              }
              
            </div>
          </div>
        </div>

        {/* <div className="card-body d-flex  p-0">
          <div
            className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss me-2"
          >
            <i className="feather-thumbs-up text-dark text-dark text-grey-600 btn-round-sm font-sm"></i>{" "}
            10 Liked
          </div>

          <a
            className="pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss"
          >
            <i className="feather-repeat text-dark text-grey-600 btn-round-sm font-sm"></i>
            <span className="d-none-xss">
              2 Reposted
            </span>
          </a>
        </div> */}
        <div className="position-absolute right-0 top-5 me-1">
          {/* <span className="text-grey-600">{">>>"}</span> */}
          <span className="card  me-1 text-grey-500 fw-600 text-uppercase font-xssss float-left rounded-3 border-dark-md d-inline-block mt-3 me-3 lh-34 text-center pointer ls-3 w50 mb-3">{t('AdvertisementView1')}</span>
        </div>
      </div>
     {
                props.item.url ?
                <p className="fw-500 text-grey-600 lh-26 font-xssss w-100 ms-2 mb-0 mt-1">
                 {props.item.url}
                </p>
                :
              <></>

              } 
              
        <div className="card-body d-flex  p-0">
            <div
              className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss me-2"
            >
              <i className="feather-radio text-dark text-dark text-grey-600 btn-round-sm font-sm"></i>{" "}
              {t('AdvertisementView2')}
            </div>
          </div>
    </div>
    :<></>
    }
    </div>

  );
};

export default AdvertisementView;
