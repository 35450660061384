import React, { useState, useEffect, useRef, useContext } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { ApiContext } from "../context/ApiContext";
import AttributeEachforAdModal from "./AttributeEachforAdModal";

const AttributesModalforAd = (props) => {
    const {
        getAttributesWithScroll,  // 無限スクロール用の関数
        searchAttributes,  // 検索用の関数
    } = useContext(ApiContext);

    const [showattribute, setShowattribute] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [noMoreData, setNoMoreData] = useState(false);
    const loaderRef = useRef(null);
    const { t } = useTranslation();

    // 初期の無限スクロール用データ取得
    useEffect(() => {
        resetAttributes();  // 初期化してから無限スクロールを開始
    }, [props.lan]);

    // スクロールがボトムに達した時の処理
    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        });
    
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }
        
        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loaderRef.current]);
    

    // 無限スクロールでデータを取得
    const fetchMoreAttributes = async () => {
        setIsLoading(true);
        const result = await getAttributesWithScroll(props.lan, count);
        if (result.length === 0) {
            setNoMoreData(true);
        } else {
            setShowattribute(prev => [...prev, ...result]);
            setCount(prev => prev + 1);
        }
        setIsLoading(false);
    };

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isLoading && !noMoreData) {
            fetchMoreAttributes();
        }
    };

    // 検索機能
    const handleInputChange = async (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (value.trim() === '') {
            resetAttributes();  // 検索がクリアされたら無限スクロールに戻る
        } else {
            const result = await searchAttributes(value, props.lan);
            setShowattribute(result);
            setNoMoreData(true);  // 検索結果表示中は無限スクロールを停止
        }
    };

    const resetAttributes = () => {
        setShowattribute([]);
        setCount(0);
        setNoMoreData(false);
        fetchMoreAttributes();
    };

    return (
        <Dialog
            className="animated"
            open={props.isVisible}
            onClose={props.handleClose}
            aria-labelledby="max-width-dialog-title"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogContent className="card w-000 shadow-xss rounded-xxl border-0 ps-0 pt-1 pe-0 pb-3 mt-1 mb-1">
                <form className="pt-0 pb-0 ms-auto">
                    <div className="search-form-2 mt-1 mb-3 me-5">
                        <i className="ti-search font-xss"></i>
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-light-sm h50"
                            placeholder=""
                        />
                    </div>
                </form>
                <div
                    className="position-absolute right-0 top-5 pointer me-3"
                    onClick={() => props.handleClose()}
                >
                    <i className="ti-close text-grey-400 fw-700 font-xsss"></i>
                </div>
                <div
                    className="position-absolute right-0 bottom-5 pointer me-3"
                    onClick={() => props.handleClose()}
                >
                    <h2 className="card bg-current border-0 shadow-sm mt-2 ms-2 ps-2 pe-2 pt-2 pb-2 font-xss  lh-1 text-white-pink fw-600">
                        {t('AttributesModal2')}
                    </h2>
                </div>
                <div
                    className="position-absolute left-0 top-5 pointer ms-2"
                >
                    <h2 className="card border-0 shadow-md mt-2 ms-2 ps-2 pe-2 pt-2 pb-2 font-xss  lh-1 text-dark fw-600">
                        {t('AttributesModal1')}
                    </h2>
                </div>
                <div className="row justify-content-center mb-0">
                    {showattribute.map((attribute) => (
                        <AttributeEachforAdModal 
                            key={attribute.id} 
                            attributeData={attribute} 
                            handleClose={props.handleClose} 
                            tag={props.tag}
                            setTag={props.setTag}
                            setTagName={props.setTagName}
                            tagName={props.tagName}
                        />
                    ))}
                </div>
                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }}></div>
                {isLoading && (
                    <div className="card-body p-0 mb-3">
                        <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                            <div className="stage">
                                <div className="dot-typing"></div>
                            </div>
                        </div>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AttributesModalforAd;
