import React, { useContext, useCallback, useState } from "react";
import { ApiContext } from "../context/ApiContext";
import { Link, useHistory } from "react-router-dom";
import getCroppedImg from "./getCroppedImg";
import "./styles.css";
import CropperModalforprof from "./CropperModalforprof";
import {
  Button,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { SnackbarContext } from "../context/SnackbarContext";
import { useTranslation } from 'react-i18next';


export const ASPECT_RATIO = 1 / 1;
export const CROP_WIDTH = 400;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: 30,
      minWidth: "100%",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      flexFlow: "column",
      "& .file-upload-container": {
        width: 500,
        marginTop: 10,
        "& .button": {
          backgroundColor: "#00A0FF",
          color: "white",
        },
      },
      "& .img-container": {
        marginTop: 40,
        width: `${CROP_WIDTH}px`,
        height: `${CROP_WIDTH / ASPECT_RATIO}px`,
        display: "flex",
        alinItems: "center",
        borderRadius: 5,
        border: "1px solid gray",
        overflow: "hidden",
        backgroundColor: "#EAEAEA",
        "& .img": {
          width: "100%",
          objectFit: "contain",
          backgroundColor: "#EAEAEA",
        },
        "& .no-img": {
          backgroundColor: "#EAEAEA",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#000",
        },
      },
    },
    validationWrapper: {
      float: "right"
    }
  }
})

const AccountEach = () => {
  const classes = useStyles();
  const history = useHistory();
  const { newSnack } = useContext(SnackbarContext);
  const {
    profile,
    editedProfile,
    setEditedProfile,
    setCover,
    // coverBack,
    createProfile,
    editProfile,
  } = useContext(ApiContext);

  /** Cropモーダルの開閉 */
  const [isOpen, setIsOpen] = useState(false);

  /** アップロードした画像URL */
  const [imgSrc, setImgSrc] = useState("");

  /** 画像の拡大縮小倍率 */
  const [zoom, setZoom] = useState(1);
  /** 画像拡大縮小の最小値 */
  const [minZoom, setMinZoom] = useState(1);

  /** 切り取る領域の情報 */
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  /** 切り取る領域の情報 */
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  /**
   * ファイルアップロード後
   * 画像ファイルのURLをセットしモーダルを表示する
   */
  const onFileChange = useCallback(async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || "");
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);

  /**
   * Cropper側で画像データ読み込み完了
   * Zoomの最小値をセットしZoomの値も更新
   */
  const onMediaLoaded = useCallback((mediaSize) => {
    const { width, height } = mediaSize;
    const mediaAspectRadio = width / height;
    if (mediaAspectRadio > ASPECT_RATIO) {
      // 縦幅に合わせてZoomを指定
      const result = CROP_WIDTH / ASPECT_RATIO / height;
      setZoom(result);
      setMinZoom(result);
      return;
    }
    // 横幅に合わせてZoomを指定
    const result = CROP_WIDTH / width;
    setZoom(result);
    setMinZoom(result);
  }, []);

  /**
   * 切り取り完了後、切り取り領域の情報をセット
   */
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  /**
   * 切り取り後の画像を生成し画面に表示
   */
  const showCroppedImage = useCallback(async () => {
    if (!croppedAreaPixels) return;
    try {
      const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
      const file = new File([croppedImage], `avatar_upload.jpg`, {
        type: croppedImage.type,
      });
      setCover(file);
      editProfile(file);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imgSrc, profile.nickName, setCover]);

  const handleInputChange = () => (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setEditedProfile(prevProfile => {
      let updatedProfile = { ...prevProfile, [name]: value };

      if (['birthYear', 'birthMonth', 'birthDay'].includes(name)) {
        const formattedMonth = String(updatedProfile.birthMonth).padStart(2, '0');
        const formattedDay = String(updatedProfile.birthDay).padStart(2, '0');
        const newBirthday = `${updatedProfile.birthYear}-${formattedMonth}-${formattedDay}`;
        updatedProfile = { ...updatedProfile, birthday: newBirthday };
      }

      return updatedProfile;
    });
  };

  const submit = () => {
    if (!editedProfile.nickName || editedProfile.nickName ==="default" || !editedProfile.birthday) {
      newSnack("warning", t('AccountEach11'));
      return;
    }
    editProfile();
    history.push('/settings');
    
  }

  console.log(editedProfile)

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 121 }, (_, i) => currentYear - i);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  // console.log("AccountEach", editedProfile.sex);
  // console.log(profile)
  const { t } = useTranslation();

  

  return (
    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
      {!editedProfile ? <></> :
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to="/settings" className="d-inline-block mt-2">
                    <i className="ti-arrow-left font-sm text-white"></i>
                  </Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                  {t('AccountEach1')}
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      {profile.img ? (
                        <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                          <img
                            src={profile.img}
                            alt="avater"
                            className="shadow-sm rounded-3 w-100"
                          />
                        </figure>
                      ) : (
                        <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                          <img
                            src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                            alt="avater"
                            className="shadow-sm rounded-3 w-100"
                          />
                        </figure>
                      )}
                      <h2 className="fw-700 font-sm text-grey-900 mt-3">
                        {profile.nickName ==="default"? "未設定":profile.nickName}
                      </h2>
                      <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">
                        {profile.created_on}
                      </h4>
                    </div>
                  </div>

                  <form action="#" encType="multipart/form-data">
                    <div className="row">
                      <div className="col-lg-12 ">
                        <div className={classes.root}>
                          <div className="file-upload-container">
                            <Button
                              className="bg-current text-center text-white font-xsss fw-600 pt-2 pb-2 mb-3  w175 rounded-3 d-inline-block"
                              variant="contained"
                              component="label"
                            >
                              {t('AccountEach2')}
                              <input type="file" hidden onChange={onFileChange} />
                            </Button>
                          </div>
                          <CropperModalforprof
                            crop={crop}
                            setCrop={setCrop}
                            zoom={zoom}
                            setZoom={setZoom}
                            onCropComplete={onCropComplete}
                            open={isOpen}
                            onClose={() => setIsOpen(false)}
                            imgSrc={imgSrc}
                            showCroppedImage={showCroppedImage}
                            onMediaLoaded={onMediaLoaded}
                            minZoom={minZoom}
                          />
                          {imgSrc ? (
                            <div className="card-body  position-relative">
                              <label className="mont-font fw-600 font-xssss mb-2">
                              {t('AccountEach3')}
                              </label>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                          {t('AccountEach4')}<span className="text-danger font-xs">{" "} *</span>
                            {!editedProfile.nickName | editedProfile.nickName ==="default" ?<span className="text-danger font-xs">{" "} *</span>: <></> }
                          </label>
                          <input
                            type="text"
                            maxLength={20}
                            className={editedProfile.nickName ==="default" | editedProfile.nickName ? "form-control border-danger" : "form-control  "}
                            value={editedProfile.nickName === "default" ? "" :editedProfile.nickName}
                            name="nickName"
                            onChange={handleInputChange()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">
                          {t('AccountEach5')}<span className="text-danger font-xs">{" "} *</span>
                          {editedProfile.birthday ? <></> : <span className="text-danger font-xs">{" "} *</span>}
                        </label>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <select 
                            name="birthYear" 
                            value={editedProfile.birthYear} 
                            onChange={handleInputChange()} 
                            className={editedProfile.birthday ? "form-select border-2 me-1 font-xss" : "me-1 form-select border-2 border-danger font-xss"}>
                            <option value="">{editedProfile.birthday ? editedProfile.birthday.slice(0,4): t('AccountEach12')}</option>
                            {years.map(year => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          <select 
                            name="birthMonth" 
                            value={editedProfile.birthMonth} 
                            onChange={handleInputChange()} 
                            className={editedProfile.birthday ? "form-select border-2 me-1 ms-1 font-xss" : "form-select border-2  border-danger me-1 ms-1 font-xss"}>
                            <option value="">{editedProfile.birthday ? editedProfile.birthday.slice(5,7): t('AccountEach13')}</option>
                            {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                          <select 
                            name="birthDay" 
                            value={editedProfile.birthDay} 
                            onChange={handleInputChange()} 
                            className={editedProfile.birthday ? " form-select border-2 ms-1 font-xss" : "form-select border-2 ms-1 border-danger font-xss"}>
                            <option value="">{editedProfile.birthday ? editedProfile.birthday.slice(8,10): t('AccountEach14')}</option>
                            {days.map(day => (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                          {t('AccountEach6')}<span className="text-danger font-xs">{" "} *</span>
                          </label>
                          <br></br>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="position"
                              name="sex"
                              value={editedProfile.sex}
                              onChange={handleInputChange()}
                              row
                            >
                              <FormControlLabel
                                value="0"
                                control={<Radio color="default" />}
                                label= {t('AccountEach15')}
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio color="default" />}
                                label={t('AccountEach16')}
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="3"
                                control={<Radio color="default" />}
                                label={t('AccountEach17')}
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="4"
                                control={<Radio color="default" />}
                                label={t('AccountEach18')}
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                        {t('AccountEach7')}

                        </label>
                        <textarea
                          className={"form-control mb-0 p-3 h100 bg-greylight lh-16 font-xss" 
                        }
                          name="caption"
                          maxLength={100}
                          rows="5"
                          value={editedProfile.caption}
                          onChange={handleInputChange()}
                        ></textarea>
                      </div>

                      <div className="card-body p-lg-5 w-100 border-0">
              <div className="row justify-content-center mb-4">
                <div className="col-lg-4 text-center">
                        {
                          editedProfile.id ? (
                            <Button
                              className="bg-current text-center text-white font-xsss fw-600 p-3  w175 rounded-3 ms-1 me-1 mt-3"
                              variant="contained"
                              component="label"
                              onClick={submit}
                            >
                              {t('AccountEach8')}
                            </Button>
                          ) : (
                            // <button className="pointer me-2 bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={() => editProfile()}>編集結果を保存</button>)
                            <Button
                              className="bg-current text-center text-white font-xsss fw-600 p-3 ms-1 me-1 w175 rounded-3 mt-3"
                              variant="contained"
                              component="label"
                              onClick={() => createProfile()}
                            >
                              {t('AccountEach9')}
                            </Button>
                          )
                          // <button className="bg-current me-2 text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={() => createProfile()}>編集結果を保存</button>)
                        }
                        {/* <Button
                          className="bg-dark text-center text-white font-xsss fw-600 p-3 ms-1 me-1 w175 rounded-3 mt-3"
                          variant="contained"
                          component="label"
                          onClick={() => deleteProfile()}
                        >
                          {t('AccountEach10')}
                        </Button> */}
                        {/* <button onClick={() => deleteProfile()} className='bg-grey text-center text-black mt-3 font-xsss fw-600 p-3 w175 rounded-3 d-inline-block'>プロフィールを削除</button> */}
                      </div>
                    </div>
                    </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </div>
  );
};

export default AccountEach;