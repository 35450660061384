import React, { useState, Fragment, useContext, useEffect, useRef } from "react";
import { Tooltip, Fab, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TelegramIcon from '@material-ui/icons/Telegram';
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
// import Slider from "react-slick";
import "react-image-lightbox/style.css";
import LinearProgress from "@mui/joy/LinearProgress";
import MonoPicture from "../components/MonoPicture";
import Createpost from "../components/Createpost";
// import Postview from "./Postview";
import PostviewforPage from "./PostviewforPage";
import AdvertisementView from "../components/AdvertisementView";
import { ApiContext } from "../context/ApiContext";
import Button from "@mui/joy/Button";
import { useCookies } from "react-cookie";
import LoginComponent from "../components/LoginComponent";
import { useHistory, useParams } from "react-router-dom";
import copy from '../context/clipboard';
import { SnackbarContext } from "../context/SnackbarContext";
import { Helmet } from "react-helmet"
import NoPostYet from "../components/NoPostYet";
import OriginDataEach from "../components/OriginDataEach";
import { useTranslation } from 'react-i18next';
import { AddComment } from "@material-ui/icons";
import axios from "axios";
import FollowingPage from "./FollowingPage";
import RelatedPages from "./RelatedPages";
import DataModal from "../components/DataModal";


const useStyles = makeStyles((theme) => {
  return {
    PostButton: {
      backgroundColor: green[400],
      "&:hover": {
        backgroundColor: green[200],
      }
    },
    PostButtonContainer: {
      position: "fixed",
      width: "100%",
      zIndex: "999",
      left: "350px",
      bottom: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('xs')]: {  // Media query
        left: '150px',
      },
    },
    gentleShake: {
      animation: `$gentleShake 0.5s 3`, // Add gentle shaking animation
    },
    tooltip: {
      position: "absolute",
      bottom: "140px", // Adjust according to your button's position
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent white
      color: "#00FF00", // Futuristic green color
      padding: "10px 15px",
      borderRadius: "10px",
      fontSize: "14px",
      opacity: 0,
      transition: "opacity 0.5s ease-in-out",
      boxShadow: "0 0 10px rgba(0, 255, 0, 0.5)",
      border: "1px solid rgba(0, 255, 0, 0.5)",
      backdropFilter: "blur(5px)",
      left: "50%",
      transform: "translateX(-50%)", // Center horizontally
      [theme.breakpoints.down('xs')]: { // Media query for small screens
        left: "35%",
        transform: "translateX(-50%)", // Ensure it stays centered
      },
      "&::before": {
        content: '""',
        position: "absolute",
        bottom: "-20px", /* Adjust if needed */
        left: "50%",
        transform: "translateX(-50%)",
        borderWidth: "10px",
        borderStyle: "solid",
        borderColor: "rgba(255, 255, 255, 0.1) transparent transparent transparent", /* Arrow with semi-transparent white fill */
      },
    },
    tooltipShow: {
      opacity: 1,
      animation: `$fadeInUp 0.5s ease-in-out`,
    },
    "@keyframes fadeInUp": {
      from: {
        opacity: 0,
        transform: "translateY(20px)",
      },
      to: {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  
    "@keyframes gentleShake": {
      "0%": { transform: "translateX(1px)" },
      "10%": { transform: "translateX(-1px)" },
      "20%": { transform: "translateX(-2px)" },
      "30%": { transform: "translateX(2px)" },
      "40%": { transform: "translateX(1px)" },
      "50%": { transform: "translateX(-1px)" },
      "60%": { transform: "translateX(-2px)" },
      "70%": { transform: "translateX(2px)" },
      "80%": { transform: "translateX(-1px)" },
      "90%": { transform: "translateX(1px)" },
      "100%": { transform: "translateX(1px)" },
    },
    cssloadLoader: {
      position: 'relative',  // 'absolute'から変更
      width: '30px',
      height: '30px',
      margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
      perspective: '780px',
      left: '2px',
    },
    cssloadInner: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: '50%',
      '&.cssloadOne': {
        left: '0%',
        top: '0%',
        animation: '$cssloadRotateOne 4s linear infinite',
        borderBottom: '3px solid #79ac78',
      },
      '&.cssloadTwo': {
        right: '0%',
        top: '0%',
        animation: '$cssloadRotateTwo 4s linear infinite',
        borderRight: '3px solid #79ac78',
      },
      '&.cssloadThree': {
        right: '0%',
        bottom: '0%',
        animation: '$cssloadRotateThree 4s linear infinite',
        borderTop: '3px solid #79ac78',
      },
    },
    cssloadLoader2: {
      position: 'relative',  // 'absolute'から変更
      width: '80px',
      height: '80px',
      margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
      perspective: '780px',
      left: '7px',
    },
    cssloadInner2: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: '50%',
      '&.cssloadOne': {
        left: '0%',
        top: '0%',
        animation: '$cssloadRotateOne 4s linear infinite',
        borderBottom: '3px solid #fff0f5',
      },
      '&.cssloadTwo': {
        right: '0%',
        top: '0%',
        animation: '$cssloadRotateTwo 4s linear infinite',
        borderRight: '3px solid #fff0f5',
      },
      '&.cssloadThree': {
        right: '0%',
        bottom: '0%',
        animation: '$cssloadRotateThree 4s linear infinite',
        borderTop: '3px solid #fff0f5',
      },
    },
    '@keyframes cssloadRotateOne': {
      '0%': {
        transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(360deg)',
      },
    },
    '@keyframes cssloadRotateTwo': {
      '0%': {
        transform: 'rotateX(50deg) rotateY(10deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(50deg) rotateY(10deg) rotateZ(360deg)',
      },
    },
    '@keyframes cssloadRotateThree': {
      '0%': {
        transform: 'rotateX(35deg) rotateY(55deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(35deg) rotateY(55deg) rotateZ(360deg)',
      },
    },
    bodyStyle: {
      // background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1,#477451,#FFE5E5)',
      backgroundSize: '400% 400%',
      animation: '$gradient 20s ease infinite',
      // height: '100vh'
    },
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: '0% 50%'
      },
      "50%": {
        backgroundPosition: '100% 50%'
      },
      "100%": {
        backgroundPosition: '0% 50%'
      }
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    line: {
      display: 'block',
      position: 'relative',
      '&::after': {
        background: 'linear-gradient(to right,#79ac78 0%,#79ac78 50%,#79ac78 100%)',
        content: '""',
        display: 'block',
        height: '2px',
        width: 0,
        transform: 'translateX(-50%)',
        transition: '2s cubic-bezier(0.22, 1, 0.36, 1)',
        position: 'absolute',
        bottom: 0,
        left: '50%',
      },
      '&.is-animated::after': {
        width: '100%',
      },
    },
    bg: {
      '& $bgWrap': {
        position: 'relative',
        display: 'inline-block',
        marginTop: '5px',
      },
      '&.is-animated $bgWrap::before': {
        animation: '$bgAnimation 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards',
        background: 'linear-gradient(to right, #79ac78 0%,#3b79cc 50%,#42d3ed 100%)',
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transformOrigin: 'left center',
      },
      '& $bgWrap $inn': {
        color: '#fff',
        display: 'inline-block',
        fontSize: '13px',
        fontWeight: 'bold',
        padding: '4px 10px',
        position: 'relative',
        zIndex: 1,
      },
    },
    bgWrap: {}, // この空のスタイルは、ネストされたセレクタの参照用
    inn: {}, // この空のスタイルは、ネストされたセレクタの参照用
    '@keyframes bgAnimation': {
      '0%': {
        opacity: 0,
        transform: 'scaleX(0) translateX(-5%)',
      },
      '30%': {
        transform: 'scaleX(1) translateX(0)',
        opacity: 1,
      },
      '100%': {
        transform: 'scaleX(1) translateX(0)',
        opacity: 1,
      },
    },
    DataButton: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      },
    DataButtonContainer: {
        position: "fixed",
        width: "100%",
        zIndex: "999",
        left: "350px",
        bottom: "135px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          left: '150px',
        },
    },
    cssloadLoader2: {
      position: 'relative',  // 'absolute'から変更
      width: '30px',
      height: '30px',
      margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
      perspective: '780px',
      left: '1px',
    },
    cssloadInner: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: '50%',
      '&.cssloadOne': {
        left: '0%',
        top: '0%',
        animation: '$cssloadRotateOne 4s linear infinite',
        borderBottom: '3px solid #fff0f5',
      },
      '&.cssloadTwo': {
        right: '0%',
        top: '0%',
        animation: '$cssloadRotateTwo 4s linear infinite',
        borderRight: '3px solid #fff0f5',
      },
      '&.cssloadThree': {
        right: '0%',
        bottom: '0%',
        animation: '$cssloadRotateThree 4s linear infinite',
        borderTop: '3px solid #fff0f5',
      },
    },
    '@keyframes cssloadRotateOne': {
      '0%': {
        transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(360deg)',
      },
    },
    '@keyframes cssloadRotateTwo': {
      '0%': {
        transform: 'rotateX(50deg) rotateY(10deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(50deg) rotateY(10deg) rotateZ(360deg)',
      },
    },
    '@keyframes cssloadRotateThree': {
      '0%': {
        transform: 'rotateX(35deg) rotateY(55deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(35deg) rotateY(55deg) rotateZ(360deg)',
      },
    },
    bodyStyle: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      // height: '100vh'
    },
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: '0% 50%'
      },
      "50%": {
        backgroundPosition: '100% 50%'
      },
      "100%": {
        backgroundPosition: '0% 50%'
      }
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
  
    },  
  };
});

const Mono = () => {
  const history = useHistory();
  const classes = useStyles();
  const { newSnack } = useContext(SnackbarContext);
  const [isOpen, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [postsforintpagebyscroll, setPostsforintpagebyscroll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [averageRate, setAverageRate] = useState(0);
  const [limitpost, setLimitpost] = useState(0);

  const { pageid } = useParams([]);
  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);


  const {
    profile,
    profiles,
    intpage,
    postsforintpage,
    followPage,
    followingpage,
    affiliates,
    owningpage,
    newUserIntAttribute,
    getPageInfo,
    newUserIntPage,
    newUserIntPageWithScroll,
    ads,
    getAds,
    getAdsforpage,
    adsforpage,
    mymonoposts,
    pageattribute,
    deleteFollowingPage,
    fetchRelatedMonoPages,
    relatedPages,
    setIntPageIdOnly,
    isFollowed,
    isOwned,
    getAttributesForMonoPage,
    newUserIntUser,
    pageCheck,
    setIsFollowed,
    getSpecificProfileAll
  } = useContext(ApiContext);
  const loaderRef = useRef(null);
  const [noMoreData, setNoMoreData] = useState(false);
  // const [tagsforpage, setTagsforpage] = useState([]);

  const [showTooltip, setShowTooltip] = useState(false);
  const [shake, setShake] = useState(false);

  const [isShowDataModal, setShowDataModal] = useState(false);

  const handleDataClick = () => {
    if (!profile.userProfile) {
      newSnack("error", t('Header14'));
      return;
    }
    setShowDataModal(true);
  }

  const handleDataModalClose = () => {
    setShowDataModal(false);
  }
  // useEffect(() => {
  //   setPostsforintpagebyscroll([]); // 投稿データをリセット
  //   setNoMoreData(false);  // データの取得終了状態をリセット
  //   setCount(0);  // カウントをリセット
  // }, [pageid]); // pageid が変更された時のみ実行
  

  

  

  useEffect(() => {
    setShake(true);
    setShowTooltip(true);
    const shakeTimer = setTimeout(() => setShake(false), 1000); // 3 shakes * 0.5s
    const tooltipTimer = setTimeout(() => setShowTooltip(false), 3000);
    return () => {
      clearTimeout(shakeTimer);
      clearTimeout(tooltipTimer);
    };
  }, []);

  useEffect(() => {
    getAdsforpage(pageid);
    getPageInfo(pageid);
    if (profile && profile.userProfile) {
      pageCheck(profile.userProfile, pageid);
    }
    setCount(0);  // カウントをリセット
    setPostsforintpagebyscroll([]); // 投稿データをリセット
    setNoMoreData(false);  // データの取得終了状態をリセット
    // fetchData();
    // 初期化とデータ取得
    // setCount(0); // countをリセットする
    // setPostsforintpagebyscroll([]); // 既存の投稿をリセット
  }, [profile, pageid]);

  
  useEffect(() => {
    fetchData();
  }, [profile, pageid,count]); // count のみ依存配列に指定
  



  // useEffect(() => {
  //   if (count === 0) {
  //     fetchData();
  //   }
  // }, [profile, pageid]);
  
  // useEffect(() => {
  //   if (count > 0) {
  //     fetchData();
  //   }
  // }, [count]);

  // useEffect(() => {
  //   intpage.attribute && setTagsforpage(pageattribute.filter((each) => intpage.attribute.includes(each.id) ))
  // }, [intpage])

  useEffect(() => {
    // totalCountが変わるたびに、limitpostを再計算します
    setLimitpost(50 - (totalCount % 50));
  }, [totalCount]);

  const toAttribute = (id) => {
    const attributeId = id;
    history.push(`/recommend?attid=${attributeId}`);
  };



  const [cookies] = useCookies(["current-token"]);




  

  const [tagsForPage, setTagsForPage] = useState([]);

  useEffect(() => {
    const fetchAttributes = async () => {
      const attributes = await getAttributesForMonoPage(pageid);
      setTagsForPage(attributes);
    };

    fetchAttributes();
  }, [pageid]);



  const randnum = Math.floor(Math.random() * 29);

  const fetchData = async () => {
    setIsLoading(true);
  
    const result = await newUserIntPageWithScroll(pageid, count);
  
    // 既に存在するデータと重複していないか確認
    const newPosts = result.res.filter(post => 
      !postsforintpagebyscroll.some(existingPost => existingPost.id === post.id)
    );
  
    if (newPosts.length === 0) {
      setNoMoreData(true);  // 追加データがない場合、これ以上データを取得しないように設定
    } else {
      setPostsforintpagebyscroll(prevItems => [...prevItems, ...newPosts]);
    }
  
    setAverageRate(Math.round(result.aggregate.rating__sum / result.totalCount));
    setTotalCount(result.totalCount);
  
    setIsLoading(false);
  };
  


  const followTopic = async () => {
    if (!profile || !profile.id) {  // ログインしていない場合
        newSnack("error", t('Header14'));  // エラーメッセージを表示
        return;  // API通信を行わずに処理を終了
    }

    try {
        await followPage(Number(pageid));
        setIsFollowed(true);  // フォロー後、即座に状態を更新
    } catch (error) {
        console.error("Error following the page:", error);
    }
};
  

const unfollowTopic = async () => {
  if (!profile || !profile.id) {  // ログインしていない場合
      newSnack("error", t('Header14'));  // エラーメッセージを表示
      return;  // API通信を行わずに処理を終了
  }

  try {
      await deleteFollowingPage(pageid);
      setIsFollowed(false);  // フォロー解除後、即座に状態を更新
  } catch (error) {
      console.error("Error unfollowing the page:", error);
  }
};


  const toSpecificUserPage = (id) => {
    if (profile.userProfile === id) {
      history.push("/userpage");
    } else {
      history.push(`/user/${id}`);
    }
  };

  const sharePage = () => {
    copy(window.location.href);
    newSnack("info", t('Mono2'));
  }

  const MIN = 0
  const MAX = 50

  



  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
  
    const observer = new IntersectionObserver(handleObserver, options);
  
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
  
    return () => {
      observer.disconnect();
    };
  }, []);
  
  const handleObserver = (entities) => {
    const target = entities[0];
  
    if (target.isIntersecting && !isLoading && !noMoreData) {
      setCount((prev) => prev + 1);
    }
  };
  
  

  
  const toGetPage = () => {
    if (!profile || !profile.id) {  // ログインしていない場合
        newSnack("error", t('Header14'));  // エラーメッセージを表示
        return;  // API通信を行わずに処理を終了
    }

    setIntPageIdOnly(pageid);
    history.push("/getpage");
};

const toOwnPage = () => {
  if (!profile || !profile.id) {  // ログインしていない場合
      newSnack("error", t('Header14'));  // エラーメッセージを表示
      return;  // API通信を行わずに処理を終了
  }

  setIntPageIdOnly(pageid);
  history.push("/owningpage/" + pageid);
};


  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  // JSON-LD 構造化データ
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Article",
    "headline": intpage.title,
    "description": intpage.summary,
    "image": intpage.img || `${process.env.REACT_APP_API_URL} /default_meta_image.jpg`,
    "datePublished": intpage.create_on,
    "author": {
      "@type": "Person",
      "name": "Tatami.site" 
    }
  }
  const { t } = useTranslation();
  const discuss3 = intpage.point3
  const [animate, setAnimate] = useState(false);
  // コンポーネントがマウントされた時に実行される

  useEffect(() => {
    setAnimate(true);
  }, []); // 空の依存配列でコンポーネントのマウント時のみ実行

  useEffect(() => {
  fetchRelatedMonoPages(pageid);
}, [pageid]);

const handleNewPost = (newPost) => {
  setPostsforintpagebyscroll((prevPosts) => [newPost, ...prevPosts]);
};



  return (
    <Fragment>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
      <DataModal
        isVisible={isShowDataModal}
        handleClose={handleDataModalClose}
        
      />
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            
          <div className={classes.PostButtonContainer}>
            {showTooltip && (
              <div className={`${classes.tooltip} ${showTooltip ? classes.tooltipShow : ''}`}>
                {t('Mono27')} <br />
                {t('Mono28')}
              </div>
            )}
            <Tooltip title="Post Comment" placement="top-start">
              {profile.id ? (
                <Fab
                  color="secondary"
                  className={`${classes.PostButton} ${shake ? classes.gentleShake : ''}`}
                  onClick={openDialog}
                >
                  <AddComment />
                </Fab>
              ) : (
                <Fab
                  color="secondary"
                  className={`${classes.PostButton} ${shake ? classes.gentleShake : ''}`}
                  onClick={() => newSnack("error", t('Mono20'))}
                >
                  <AddComment />
                </Fab>
              )}
            </Tooltip>
          </div>
          <div className={ classes.DataButtonContainer }>
                <Tooltip title="Post Data" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={handleDataClick}
                    >
                    <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader2}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                    </div>
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={()=>newSnack("error", "投稿するにはログインしてください")}
                    >
                        <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader2}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                    </div>
                    </Fab>
                  )}
                </Tooltip>
              </div>
            <div className="row">
              
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                
                <div className="pe-2">

                  {
                  intpage.img ?
                  <img
                    src={intpage.img}
                    alt="avater"
                    className="rounded-3 w-100"
                  />:
                  <img
                    src={`${process.env.REACT_APP_API_URL} /default_meta_image.jpg`}
                    alt="avater"
                    className="rounded-3 w-100"
                  />
                  }


                  
                </div>

                
              </div>
              
              
            </div>

            <div className="row">
            
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 pt-4 p-4">
                  
                   <div className="position-absolute  right-0 top-5 pointer mt-2 me-2">
<div className="d-flex flex-row-reverse">
  {intpage.files ? (
    intpage.files.map((each, index) => (
      each ? (
        <figure
          key={index}
          className="mt-0 avatar me-1 pointer mb-1"
          onClick={() => toSpecificUserPage(each.profile.userId)}
        >
          <img
            src={process.env.REACT_APP_API_URL + each.profile.img}
            alt="avatar"
            className="shadow-sm rounded-circle w30"
          />
        </figure>
      ) : (
        <figure key={index} className="mt-0 avatar me-1 pointer mb-1">
          <img
            src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
            alt="default avatar"
            className="shadow-sm rounded-circle w30"
          />
        </figure>
      )
    ))
  ) : (
    <figure className="mt-0 avatar me-1 pointer mb-1">
      <img
        src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
        alt="default avatar"
        className="shadow-sm rounded-circle w30"
      />
    </figure>
  )}
</div>

                    <p className="font-xsssss fw-500 mb-0 text-grey-500 mt-0 ms-3">
                   {t('Mono30')}
                  </p>
                   </div>
                   

                  <div className={`${classes.bg} mb-2 mt-0 ${animate ? 'is-animated' : ''}`}>
                    <span className={classes.bgWrap}>
                      <span className={`${classes.inn}`}>{t('Mono21')}</span>
                    </span>
                    
                  </div>
                  
                  <h2 className="fw-700 font-lg mt-3 mb-2">{intpage.title}</h2>



                  <p className="font-xsss fw-500 text-grey-500 lh-30 pe-3 mt-3">
                    {intpage.summary}
                  </p>
                  <div className="row justify-content-center mb-4">
                  <div className="col-lg-12 text-center">
                  {!isFollowed || !profile.id ? (
                      <span
                        className="pointer font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase mt-2 rounded-3 ls-2 bg-current d-inline-block ms-1 me-1 text-white shadow-sm w200"
                        onClick={followTopic}  // フォロー用の関数を呼び出し
                      >
                        {t('Mono5')}
                      </span>
                    ) : (
                      <span
                        className="pointer font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase mt-2 rounded-3 ls-2 bg-grey d-inline-block ms-1 me-1 text-white shadow-sm w200"
                        onClick={unfollowTopic}  // フォロー解除用の関数を呼び出し
                      >
                        {t('Mono6')}
                      </span>
                    )}
                    <span
                      className="pointer font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase mt-2 rounded-3 ls-2 bg-current ms-1 me-1 d-inline-block text-white shadow-sm w200"
                      onClick={sharePage}
                    >
                      {t('Mono7')}
                    </span>
                    
                    {!isOwned || !profile.id ? (
                    <span
                      className="pointer font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase mt-2 rounded-3 ls-2 bg-gold-gradiant d-inline-block ms-1 me-1 text-white shadow-sm w200"
                      onClick={() => toGetPage()}
                    >

                      {t('Mono3')}
                    </span>
                  ) : (
                    <span
                      className="pointer font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 mt-3 ls-2 bg-dark d-inline-block w200 ms-1 me-1 text-white "
                      onClick={() => toOwnPage()}
                    >
                      {t('Mono4')}
                    </span>
                  )}
                  </div>
                  </div>


                  <div className={`${classes.line}  ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-600 mt-2 mb-2`}>{t('Mono22')}</div>

                  <div className="card d-block border-light-md rounded-3 overflow-hidden p-4 shadow-xss mt-2 alert-current mb-4">


                  <div className="section full mb-4 p-4 bg-light theme-dark-bg theme-light-bg rounded-3">
                      <div className="row">
                        <div className="col-12 text-center">
                          <h4 className="font-xss text-grey-600 fw-600 mt-2">
                          {t('Mono8')}
                          </h4>
                        </div>
                        {intpage.rating ? (
                          <div className="col-12 text-center">
                            <h2 className="display2-size lh-1 m-0 text-grey-900 fw-700">
                              {intpage.rating}%
                            </h2>
                          </div>
                        ) : (
                          <div className="col-12 text-center">
                            <h2 className="display2-size lh-1 m-0 text-grey-900 fw-700">
                            {t('Mono9')}
                            </h2>
                          </div>
                        )}
                        <div className="col-12 text-center">
                          <h4 className="font-xssss text-grey-600 fw-600 mt-2">
                            {totalCount}{t('Mono10')}
                          </h4>
                        </div>
                      </div>
                      {intpage.rating ? (
                        <LinearProgress
                          color="primary"
                          determinate
                          size="lg"
                          value={intpage.rating}
                          variant="soft"
                        />
                      ) : (
                        <LinearProgress
                          color="primary"
                          determinate
                          size="lg"
                          value={0}
                          variant="soft"
                        />
                      )}
                    </div>
                    <div className="card bg-transparent border-0">
                    <div className="fw-600 ms-auto ps-3 pe-0 lh-32 text-uppercase rounded-3 ls-2  position-absolute text-center top-0 mt-1 mb-5 ms-2 me-0 right-0">
                      {limitpost ? (
                            <LinearProgress
                              color="success"
                              determinate
                              size="lg"
                              value={normalise(50-limitpost)}
                              variant="soft"
                            />
                          ) : (
                            <LinearProgress
                              color="primary"
                              determinate
                              size="lg"
                              value={0}
                              variant="soft"
                            />
                          )}
                      <h2 className="fw-600 font-xssssss mt-0 ps-1 mt-1 text-current mb-0">{t('Mono19')}</h2>
                      <h2 className="fw-600 font-xssssss mt-0 ps-1 mt-0 text-current mb-0">{limitpost}{t('Mono23')}</h2>
                      </div>
                    <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 text-current mb-4">
                    {t('Mono11')}
                    </h2>
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                      <i className=" ti-crown font-xssss shadow-xs btn-round-xs bg-current fw-bold text-white position-absolute left-0 top-5"></i>
                      {intpage.goodpoint}
                    </h4>
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                      <i className="ti-help   font-xssss shadow-xs btn-round-xs bg-grey text-grey-500 fw-bold position-absolute left-0 top-5"></i>
                      {intpage.badpoint}
                    </h4>
                    <h4 className="font-xsssss fw-500 text-grey-500 mb-0 position-relative">
                      {t('Mono26')}
                    </h4>
                    
                    </div>
                    
                  </div>
                  

                  <div className={`${classes.line}  ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-600 mt-2 mb-2`}>{t('Mono24')}</div>

                  <div className="card bg-gradiant-bottom d-block border-light-md rounded-3 overflow-hidden p-4 shadow-xss mt-2 alert-current mb-0 " 
                  onClick={() => {
                    if (!isOwned) {
                      toGetPage()
                    } else { 
                      toOwnPage()
                    }
                  }}>
                    <div className="fw-600 ms-autops-3 pe-3 lh-32 text-uppercase rounded-3 ls-2  position-absolute text-center top---15 mb-5 ms-2 right-0">
                      <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                      </div>
                      <h2 className="fw-600 font-xssssss mt-1 ps-1 text-current mb-0">{t('Mono17')}</h2>
                      <h2 className="fw-600 font-xssssss mt-0 ps-1 text-current mb-0">{t('Mono18')}</h2>
                  </div>
                    
                    <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 text-current mb-4">{t('Mono12')}</h2>
                    {intpage.point1 ?
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24"><i className=" shadow-xs font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">1</i>{intpage.point1}</h4>:<></>}
                    {intpage.point2 ?
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24"><i className=" shadow-xs font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">2</i>{intpage.point2}</h4>:<></>}
                    {intpage.point3 ?
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24"><i className=" shadow-xs font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">3</i>{intpage.point3}</h4>:<></>}
                    {intpage.point4 ?
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24"><i className=" shadow-xs font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">4</i>{intpage.point4}</h4>:<></>}
                    {intpage.point5 ?
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24"><i className=" shadow-xs font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">5</i>{intpage.point5}</h4>:<></>}
                    {/* {discuss3 ? 
                    <h4 className="font-xssss fw-500 text-grey-400 mb-3 pl-35 position-relative lh-24"><i className="text-white  font-xssss btn-round-xs shadow-xs bg-current text-white position-absolute left-0 top-5">3</i>{discuss3.slice(0,15)}...</h4>:
                    <></>} */}

                    {/* <span
                      className="pointer font-xsssss position-absolute bottom---15 right-15 left-15 text-center fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white align-items-center shadow-sm "
                      onClick={() => toGetPage()}
                    >
                      {t('Mono13')}
                    </span> */}

                </div>

                <div className={`${classes.bodyStyle} card bg-gradiant-bottom2 d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss mt-2 alert-current mb-0`}  onClick={() => toGetPage()}>
                    <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 text-white-pink mb-4">{t('Mono15')}</h2>
                    <div className="d-flex">
                    <div className={classes.flexContainer2}>
                      <div className={classes.cssloadLoader}>
                        <div className={`${classes.cssloadInner2} cssloadOne`}></div>
                        <div className={`${classes.cssloadInner2} cssloadTwo`}></div>
                        <div className={`${classes.cssloadInner2} cssloadThree`}></div>
                      </div>
                      <h2 className="fw-600 font-xssssss mt-1 ps-1 text-white-pink mb-0">{t('Mono17')}</h2>
                    </div>
                    
                    <div className="card-body d-block pt-0 pb-2">
                      <div className="row ps-3 pe-3">
                        {intpage.files ?
                        intpage.files.map((item, index) => {
                          return (
                            <OriginDataEach
                              key={index}  // 一意のkeyを追加
                              item={item.title}
                            />
                          )
                        }):
                        <></>
                        }
                      </div>
                    </div>

                    </div>
                    <span
                      className="pointer font-xsssss position-absolute bottom---15 right-15 left-15 text-center fw-700 ps-0 pe-0 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white align-items-center shadow-sm "
                      onClick={() => toGetPage()}
                    >
                      {/* <i className="feather-chevrons-right text-dark text-dark text-grey-600 btn-round-sm font-sm"></i> */}
                      {t('Mono16')}
                    </span>
                  </div>  

                </div>

                <Createpost
                  isVisible={isOpen}
                  postTo={intpage.id}
                  profileData={profile}
                  handleClose={closeDialog}
                  onNewPost={handleNewPost}  // 新しい投稿があったときにリストに追加するための関数
                />
                <br />
                <div className={`${classes.line}  ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-600 mt-0 mb-2`}>{t('Mono25')}</div>

                {
                (postsforintpagebyscroll.length === 0 ? (
                  (adsforpage && adsforpage.length > 0 ? adsforpage : ads).length > 0 && (
                    <AdvertisementView
                      profile={newUserIntUser((adsforpage && adsforpage.length > 0 ? adsforpage : ads)[0].userId)}
                      item={(adsforpage && adsforpage.length > 0 ? adsforpage : ads)[0]}
                    />
                  )
                ) : (
                  postsforintpagebyscroll.map((post, index) => (
                    <span key={index}>
                      {
                        index % 3 === 2 && parseInt(index / 3) < (adsforpage && adsforpage.length > 0 ? adsforpage : ads).length && (
                          <AdvertisementView
                            profile={newUserIntUser((adsforpage && adsforpage.length > 0 ? adsforpage : ads)[parseInt(index / 3)].userId)}
                            item={(adsforpage && adsforpage.length > 0 ? adsforpage : ads)[parseInt(index / 3)]}
                          />
                        )
                      }
                      <PostviewforPage
                        postData={post}
                        profileData={post.userPostProfile}
                        reposting={post.reposting}
                        repostingProfileData={post.repostingProfile || null}
                        commentedCounts={post.commentedCounts} 
                        repostedTimes={post.repostedTimes}     
                        likedTimes={post.likedTimes}           
                      />
                    </span>
                  ))
                ))
              }

               
                {
                  postsforintpagebyscroll.length == 0 ?
                  <NoPostYet/>
                  :
                  <></>
                }
                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }} className="mb-5"></div>
                {
                  isLoading ?
                  <div className="card-body p-0 mb-3">
                  <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  
                  <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                    <div className="stage">
                      <div className="dot-typing"></div>
                    </div>
                  </div>
                
                  </div>
                </div>:
                <></>
                }
                <div className="p-4 mb-3">
                </div>
                
              </div>

              <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
              <div className="card w-100 shadow-xss rounded-xxl border-0 mt-3 mb-5 p-0">
                <div className="position-absolute d-flex right-0 top-12 mt-4 pointer me-2">
                  {
                    tagsForPage.length ?
                    tagsForPage.map((tag) => {
                      return (
                        <h2 
                          key={tag.id}  // ここで一意のkeyを追加
                          className="card pointer shadow-xs mt-2 ms-2 ps-2 pe-2 pt-1 pb-1 font-xssss lh-1 text-current fw-600" 
                          onClick={() => {toAttribute(tag.id);}}
                        >
                          {tag.attributeName.length >= 7 ? tag.attributeName.slice(0,4) : tag.attributeName}
                        </h2>
                      );
                    }) : <></>
                  }
                </div>

                <div className="ard-body d-flex align-items-center ps-4 pe-4 pt-3 pb-3">
                  <h4 className="fw-700 mb-0 font-xssss text-grey-900 mb-4">
                    {t('Mono29')}
                  </h4>
                </div>
                {
                  relatedPages.slice(0,5).map(zz =>
                    <RelatedPages
                      key={zz.id}  // こちらでもkeyを追加
                      monopage={zz}
                      setCount = {setCount}
                    />
                  )
                }
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter />
    </Fragment>
  );
};

export default Mono;
