import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { ApiContext } from "../context/ApiContext";
import SpecificUserpagePicture from "../components/SpecificUserpagePicture";
import SpecificUserpageEach from "../components/SpecificUserpageEach";
import SpecificUserProfiledetail from "../components/SpecificUserProfileDetail";
import AdvertisementView from "../components/AdvertisementView";
import NoPostYetforSpecificUser from "../components/NoPostYetforSpecificUser";
import Postview from "./Postview";
import { useTranslation } from 'react-i18next';

const SpecificUserpage = () => {
  const { profile, postsforintuser, newUserIntUser, ads, userpostWithScroll } = useContext(ApiContext);
  const { t } = useTranslation();

  const { userid } = useParams();

  useEffect(() => {
    newUserIntUser(userid);
    window.scrollTo({ left: 0, top: 0 });
  }, [userid]);

  const [count, setCount] = useState(0);
  const [postsbyscroll, setPostsbyscroll] = useState([]);
  const loaderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const result = await userpostWithScroll(count, userid);
    if (result && result.res) {
      setPostsbyscroll(prevItems => [...prevItems, ...result.res]);
    }
    setIsLoading(false);
  };

  const handleObserver = (entities, observer) => {
    const target = entities[0];

    if (target.isIntersecting && !isLoading) {
      setCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [count, userid]);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left ps-2 pe-2">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <SpecificUserpageEach userid={userid} />
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-3">
                <SpecificUserProfiledetail />
                {/* <SpecificUserpagePicture /> */}
              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {postsbyscroll &&
                  postsbyscroll.map((post, index) => (
                    <Fragment key={index}>
                      <Postview
                        postData={post}
                        profileData={post.userPostProfile}
                        reposting={post.reposting}
                        repostingProfileData={post.repostingProfile || null}
                        commentedCounts={post.commentedCounts}
                        repostedTimes={post.repostedTimes}
                        likedTimes={post.likedTimes}
                        monopage = {post.reviewToPage}  
                      />

                      {index % 3 === 2 && parseInt(index / 3) < ads.length ? (
                        <AdvertisementView
                          profile={newUserIntUser(ads[parseInt(index / 3)].userId)}
                          item={ads[parseInt(index / 3)]}
                        />
                      ) : null}
                    </Fragment>
                  ))}
                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }} className="mb-5"></div>
                {isLoading && (
                  <div className="card-body p-0 mb-3">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                      <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {postsbyscroll.length === 0 && <NoPostYetforSpecificUser />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default SpecificUserpage;
