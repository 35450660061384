import React, { useState, useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NoFollowYet = () => {
    const { t } = useTranslation();
return(
<Link to="/recommenduser">
<div className="pointer card w-100 shadow-xss rounded-xxl border-0 ps-3 pe-3 pt-3 pb-2 mb-3">
    <div className="card-body rounded-0 text-left">
    <h2 className="fw-700 display1-size display2-md-size mb-3 text-grey-700">{t('NoFollowYet1')}</h2>
    
    <h6 className="text-grey-700 font-xsss fw-500 mt-2 mb-0 lh-32 ">{t('NoFollowYet2')}</h6>
    
    </div>
    
</div>
</Link>
)
    
}

export default NoFollowYet;