import React, { useReducer, useCallback, useState, useEffect, useRef } from "react";
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import getCroppedImg from "./getCroppedImg";
import { Button, Slider } from "@material-ui/core";
import clsx from 'clsx';
import "./styles.css";
import axios from "axios";
import AttributeEach from "./AttributeEach";
import AttributeEachforModal from "./AttributeEachforModal";
import i18n from '../i18n'; 
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useHistory, useParams } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { withCookies } from "react-cookie";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarContext } from "../context/SnackbarContext";
import {
    START_FETCH,
    FETCH_SUCCESS,
    ERROR_CATCHED,
    INPUT_EDIT_LOG,
    INPUT_EDIT_REG,
    TOGGLE_MODE,
} from "./actionTypes"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const initialState = {
    isLoading: false,
    isLoginView: true,
    error: "",
    credentialsLog: {
        username: "",
        password: "",
        confirmPassword: "",
    },
    credentialsReg: {
        email: "",
        password: "",
    },
};

const loginReducer = (state, action) => {
    switch (action.type) {
        case START_FETCH: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case FETCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ERROR_CATCHED: {
            return {
                ...state,
                error: action.payload ? action.payload : "Email or password is not correct!",
                isLoading: false,
            };
        }
        case INPUT_EDIT_LOG: {
            return {
                ...state,
                credentialsLog: {
                    ...state.credentialsLog,
                    [action.inputName]: action.payload,
                },
                error: "",
            };
        }
        case INPUT_EDIT_REG: {
            return {
                ...state,
                credentialsReg: {
                    ...state.credentialsReg,
                    [action.inputName]: action.payload,
                },
                error: "",
            };
        }
        case TOGGLE_MODE: {
            return {
                ...state,
                isLoginView: !state.isLoginView,
            };
        }
        default:
            return state;
    }
};




const useStyles = makeStyles((theme) => {
    return {
      PostButton: {
        backgroundColor: green[400],
        "&:hover": {
          backgroundColor: green[200],
        }
      },
      PostButtonContainer: {
        position: "fixed",
        width: "100%",
        zIndex: "999",
        left: "350px",
        bottom: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          left: '150px',
        },
      },
      cssloadLoader: {
        position: 'relative',  // 'absolute'から変更
        width: '30px',
        height: '30px',
        margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
        perspective: '780px',
        left: '2px',
      },
      cssloadInner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        borderRadius: '50%',
        '&.cssloadOne': {
          left: '0%',
          top: '0%',
          animation: '$cssloadRotateOne 4s linear infinite',
          borderBottom: '3px solid #79ac78',
        },
        '&.cssloadTwo': {
          right: '0%',
          top: '0%',
          animation: '$cssloadRotateTwo 4s linear infinite',
          borderRight: '3px solid #79ac78',
        },
        '&.cssloadThree': {
          right: '0%',
          bottom: '0%',
          animation: '$cssloadRotateThree 4s linear infinite',
          borderTop: '3px solid #79ac78',
        },
      },
      cssloadLoader2: {
        position: 'relative',  // 'absolute'から変更
        width: '80px',
        height: '80px',
        margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
        perspective: '780px',
        left: '7px',
      },
      cssloadInner2: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        borderRadius: '50%',
        '&.cssloadOne': {
          left: '0%',
          top: '0%',
          animation: '$cssloadRotateOne 4s linear infinite',
          borderBottom: '3px solid #fff0f5',
        },
        '&.cssloadTwo': {
          right: '0%',
          top: '0%',
          animation: '$cssloadRotateTwo 4s linear infinite',
          borderRight: '3px solid #fff0f5',
        },
        '&.cssloadThree': {
          right: '0%',
          bottom: '0%',
          animation: '$cssloadRotateThree 4s linear infinite',
          borderTop: '3px solid #fff0f5',
        },
      },
      '@keyframes cssloadRotateOne': {
        '0%': {
          transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(0deg)',
        },
        '100%': {
          transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(360deg)',
        },
      },
      '@keyframes cssloadRotateTwo': {
        '0%': {
          transform: 'rotateX(50deg) rotateY(10deg) rotateZ(0deg)',
        },
        '100%': {
          transform: 'rotateX(50deg) rotateY(10deg) rotateZ(360deg)',
        },
      },
      '@keyframes cssloadRotateThree': {
        '0%': {
          transform: 'rotateX(35deg) rotateY(55deg) rotateZ(0deg)',
        },
        '100%': {
          transform: 'rotateX(35deg) rotateY(55deg) rotateZ(360deg)',
        },
      },
      bodyStyle: {
        // background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
        background: 'linear-gradient(-45deg,#79AC78,#B0D9B1,#477451)',
        backgroundSize: '400% 400%',
        animation: '$gradient 20s ease infinite',
        // height: '100vh'
      },
      "@keyframes gradient": {
        "0%": {
          backgroundPosition: '0% 50%'
        },
        "50%": {
          backgroundPosition: '100% 50%'
        },
        "100%": {
          backgroundPosition: '0% 50%'
        }
      },
      flexContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      },
      line: {
        display: 'block',
        position: 'relative',
        '&::after': {
          background: 'linear-gradient(to right, #eee 0%, #eee 50%, #eee 100%)',
          content: '""',
          display: 'block',
          height: '2px',
          width: 0,
          transform: 'translateX(-50%)',
          transition: '2s cubic-bezier(0.22, 1, 0.36, 1)',
          position: 'absolute',
          bottom: 0,
          left: '50%',
        },
        '&.is-animated::after': {
          width: '100%',
        },
      },
      bg: {
        '& $bgWrap': {
          position: 'relative',
          display: 'inline-block',
          marginTop: '5px',
        },
        '&.is-animated $bgWrap::before': {
          animation: '$bgAnimation 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards',
          background: 'linear-gradient(to right, #79ac78 0%,#3b79cc 50%,#42d3ed 100%)',
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transformOrigin: 'left center',
        },
        '& $bgWrap $inn': {
          color: '#fff',
          display: 'inline-block',
          fontSize: '13px',
          fontWeight: 'bold',
          padding: '2px 10px',
          position: 'relative',
          zIndex: 1,
        },
      },
      bgWrap: {}, // この空のスタイルは、ネストされたセレクタの参照用
      inn: {}, // この空のスタイルは、ネストされたセレクタの参照用
      '@keyframes bgAnimation': {
        '0%': {
          opacity: 0,
          transform: 'scaleX(0) translateX(-5%)',
        },
        '30%': {
          transform: 'scaleX(1) translateX(0)',
          opacity: 1,
        },
        '100%': {
          transform: 'scaleX(1) translateX(0)',
          opacity: 1,
        },
      },
      '@keyframes fadeInUp': {
        '0%': {
          opacity: 0,
          transform: 'translateY(20px)',
        },
        '100%': {
          opacity: 1,
          transform: 'translateY(0)',
        },
      },
      // アニメーションを適用するクラス
      fadeinUp: {
        animation: `$fadeInUp 0.7s ${theme.transitions.easing.easeInOut} forwards`,
      },
    
    
  
    };
  });


const LoginComponent = (props) => {
    
    const history = useHistory();
    const [state, dispatch] = useReducer(loginReducer, initialState);
    const [user, setUser] = useState(null);
    const [isAgree, setAgree] = useState(false);    

    const { setToken } = useContext(ApiContext);
    const [gProfile, setGProfile] = useState(null);
    const { newSnack } = useContext(SnackbarContext);
    const [isshowmodal, setShowmodal] =useState(false);
    const classes = useStyles();
    const [page, setPage] = useState(1);

    const nextPage = () => {
        setPage((prevPage) => (prevPage === 4 ? 1 : prevPage + 1));
    };

    const previousPage = () => {
        setPage((prevPage) => (prevPage === 5 ? 1 : prevPage - 1));
    };


    const inputChangedLog = () => (event) => {
        dispatch({
            type: INPUT_EDIT_LOG,
            inputName: event.target.name,
            payload: event.target.value,
        });
    };
    const inputChangedReg = () => (event) => {
        dispatch({
            type: INPUT_EDIT_REG,
            inputName: event.target.name,
            payload: event.target.value,
        });
    };

    const changeAgree = () => (e) => {
        setAgree(e.target.checked);
      };

    const gLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            dispatch({ type: START_FETCH });
            setUser(codeResponse);
        },
        onError: (error) => console.log(t('LoginComponent2'), error)
    });
    useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    if (res.data.verified_email) {
                        setGProfile(res.data);
                    }
                    else {
                        dispatch({ type: ERROR_CATCHED, payload: t('LoginComponent3') });
                    }
                })
                .catch((err) => console.log(err));
        }
    },
        [user]
    );
    useEffect(() => {
        if (gProfile) {
            axios.post(
                process.env.REACT_APP_API_URL + "/api/user/loginGoogle/",
                {
                    email: gProfile.email,
                    given_name: gProfile.given_name,
                    family_name: gProfile.family_name,
                    name: gProfile.name,
                    picture: gProfile.picture,
                },
                {
                    headers: { "Content-Type": "application/json" },
                }
            ).then(res => {
                // props.cookies.set("current-token", res.data.token);
                localStorage.setItem("current-token", res.data.token);
                // (res.data.token
                //     ?
                //     (window.location.href = "/home")
                //     : (window.location.href = "/login"));
                dispatch({ type: FETCH_SUCCESS });
            }).catch(err => {
                dispatch({ type: ERROR_CATCHED });
            });
        }
    }, [gProfile, props]);

    const login = async (event) => {
        event.preventDefault();
        if (state.isLoginView) {
            try {
                dispatch({ type: START_FETCH });
                const res = await axios.post(
                    process.env.REACT_APP_API_URL + "/authen/",
                    state.credentialsLog,
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                );
                // props.cookies.set("current-token", res.data.token);
                localStorage.setItem("current-token", res.data.token);
                dispatch({ type: FETCH_SUCCESS });
                newSnack("success", t('LoginComponent4'));
                // (res.data.token
                //     ?
                //     (window.location.href = "/home")

                //     : (window.location.href = "/login"));
                setToken(res.data.token);
            } catch (err) {
                const errData = err.response.data;
                if (errData.username)
                    newSnack("error", errData.username);
                else if (errData.password)
                    newSnack("error", errData.password);
                else
                    newSnack("error", t('LoginComponent5'));
                dispatch({ type: ERROR_CATCHED });
            }
        } else {
            if (isAgree) {
                dispatch({ type: START_FETCH });
                await axios.post(
                    process.env.REACT_APP_API_URL + "/api/user/register/",
                    state.credentialsReg,
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                ).then(function () {              
                    dispatch({ type: FETCH_SUCCESS });
                    // dispatch({ type: TOGGLE_MODE });
                    newSnack("success", t('LoginComponent6'));
				    history.push('/please-verify');
                }).catch(function (e) {
                    const pairs = Object.entries(e.response.data);
                    dispatch({ type: ERROR_CATCHED, inputName: 'error', payload: pairs[0][0]+':'+pairs[0][1], });
                    newSnack("error", t('LoginComponent7'));
                });
            } else {
                dispatch({ type: ERROR_CATCHED, inputName: 'error', payload: t('LoginComponent8') });
            }
        }
    };

    const toggleView = () => {
        dispatch({ type: TOGGLE_MODE });
    };

    const handleModalClick = () => {
        setShowmodal(true)
    }

    const handleModalClose = () => {
        setShowmodal(false)
    }
    const { t } = useTranslation();

    return (
        <div className="card shadow-0 border-0 ms-auto me-auto">
            <div className="card-body rounded-0 text-left">
                <div className="d-flex">
                    <img src="/assets/images/tatami_logo_green.png" alt="logo" className="card p-0 h30 bg-transparent me-2 ms-2 border-0 mt-3 "/>
                    <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mt-1">
                {t('Header4')}{" "}
                </span>{" "}
                </div>
                {state.isLoginView ?
                    <h2 className="fw-700 display1-md-size display2-md-size mb-3 text-current">{t('LoginComponent10')} <br />{t('LoginComponent11')}</h2> :
                    <h2 className="fw-700 display1-size display2-md-size mb-3 text-current">{t('LoginComponent12')} <br />{t('LoginComponent13')}</h2>}
                {state.isLoading && <CircularProgress />}
                <span
                  className="pointer font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm w-100 mb-2 d-flex mt-4 mb-3"
                  onClick={() =>handleModalClick()}
                >
                  
                  <i className="feather-chevrons-right text-white btn-round-sm font-sm"></i>
                  <h4 className="font-xsss text-white fw-600 mt-2">
                  {t('LoginComponent14')}
                  </h4>
                  
                </span>


                <Dialog
            className="animated"
            open={isshowmodal}
            onClose={handleModalClose}
            aria-labelledby="max-width-dialog-title"
            fullWidth={true}
            maxWidth="sm"
            PaperProps={{
                style: {
                  backgroundColor: 'transparent', 
                },
            }}

        >
              <DialogContent className={`${classes.bodyStyle} card d-block border-0 rounded-3 overflow-hidden pt-1 pb-1 pe-2 ps-2 shadow-xss alert-current mb-0 `}>
            


            <div>
              <div className={`${classes.line} is-animated  text-white-pink fw-600 text-center mb-2 mt-1 font-xss `}>{t('HowToModal8')}</div>
              <div className="text-center">
              <h2 className="fw-600 text-center font-xssss mt-5  mb-2 text-white-pink">{t('HowToModal9')}</h2>

              <button className="form-control w-75 mt-1 text-center style2-input text-current fw-600 bg-white border-1 p-0 border-0 mb-5" type="button" onClick={() => gLogin()}>
                    <svg viewBox="0 0 48 48" style={{ height: '18px', marginRight: '8px', minWidth: '18px', width: '18px', position: 'relative', verticalAlign: 'middle' }}>
                        <g>
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                        </g>
                    </svg>
                    {t('HowToModal10')}
                </button>


                    {/* <h2 className="fw-600 text-center font-xssss mt-3  text-white-pink">{t('HowToModal12')}</h2>

                    <button className="w-75 mt-0 mb-3 form-control text-center style2-input text-current fw-600 bg-white-pink border-1 p-0 border-0" onClick={() => history.push('/recommend')}>{t('HowToModal13')}</button> */}

                 </div>


                 

                 

                    

                    
            </div>
            
{/* 
            {page !== 4 && (        
            <span className="position-absolute bottom-5 mb-2 ms-2 right-15 pointer" onClick={nextPage}><i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i></span>
            )}
            {page !== 1  && (        
            <span className="position-absolute bottom-5 mb-2 ms-2 left-15 pointer" onClick={previousPage}><i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-left"></i></span>
            )} */}


           

            </DialogContent>
        </Dialog >
        </div>
        </div>
    );
}

export default withCookies(LoginComponent);
