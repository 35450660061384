import React, { useState, useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const NoPostYetforSpecificUser = () => {
    const { t } = useTranslation();

return(
<div className="card w-100 shadow-xss rounded-xxl border-0 ps-3 pe-3 pt-3 pb-2 mb-3">
    <div className="card-body rounded-0 text-left">
    <h2 className="fw-700 display1-md-size display2-md-size mb-3 text-grey-700">{t('NoPostYetforSpecificUser1')}</h2>
    </div>
</div>
)
    
}

export default NoPostYetforSpecificUser;