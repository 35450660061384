import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    clock: {
        fontFamily: "'Share Tech Mono', monospace",
        color: '#fff0f5',
        // textShadow: '0 0 20px #0aafe6',
        lineHeight: '1.2',
        textAlign: 'center',
        padding: '0px',
        borderRadius: '5px',
        boxShadow: '',
        margin: '0px',
        background: '',
      },
      'clock-date': {
        fontSize: '15px',
        margin: '0px 0',
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          fontSize: ' 3vw',
        },
      },
      'clock-time': {
        fontSize: '30px',
        fontWeight: 'bold',
        margin: '0px 0',
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          fontSize: '7vw',
        },
      },
    }));



const Clock = () => {
    const classes = useStyles();
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);
    

    const getFormattedTime = () => {
        const d = date;
        const hoursFromLastReset = (d.getHours() >= 9 && d.getHours() < 21) ? d.getHours() - 9 : (d.getHours() >= 21 ? d.getHours() - 21 : d.getHours() + 3);
        const hours = String(11 - hoursFromLastReset).padStart(2, '0');
        const minutes = String(59 - d.getMinutes()).padStart(2, '0');
        const seconds = String(59 - d.getSeconds()).padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    };
    const time = getFormattedTime();

    return (
        <div className={classes.clock}>

          <p className={classes["clock-time"]}>{time}</p>
          <p className={classes["clock-date"]}>{date.toLocaleDateString()}</p> 
        </div>
      );
    
}

export default Clock;
