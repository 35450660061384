import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardActions, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    maxWidth: 360,
    marginBottom: "1rem",
  },
  cardContent: {
    minHeight: "130px",
  },
  media: {
    height: 140,
  }
});

const RecommenduserEach = ({ profileeach }) => {
  const classes = useStyles();
  const {
    profile,
    newRequestFriend,
    deleteFriendRequest,
    getFollowCheck
  } = useContext(ApiContext);
  const history = useHistory();

  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    if (profile.id) {  // ログインしている場合のみフォロー状態を確認
      const checkFollowStatus = async () => {
        const followCheck = await getFollowCheck(profileeach.userProfile);
        setIsFollowed(followCheck.following);
      };
  
      checkFollowStatus();
    }
  }, [profile.id, profileeach.userProfile]);  // 依存関係はそのまま
  

  const createNewRequest = async () => {
    const uploadDataAsk = new FormData();
    uploadDataAsk.append("askTo", profileeach.userProfile);
    uploadDataAsk.append("approved", false);
    await newRequestFriend(uploadDataAsk);
    setIsFollowed(true);
  };

  const handleUnfollow = async () => {
    await deleteFriendRequest(profile.userProfile, profileeach.userProfile);
    setIsFollowed(false);
  };

  const toSpecificUserPage = () => {
    if (profile.userProfile === profileeach.userProfile) {
      history.push("/userpage");
    } else {
      history.push(`/user/${profileeach.userProfile}`);
    }
  };

  const { t } = useTranslation();

  const createdDate = new Date(profileeach.created_on);
  const today = new Date();
  const threeDaysAgo = new Date(today);
  threeDaysAgo.setDate(today.getDate() - 2);
  const isNew = createdDate > threeDaysAgo;

  return (
    <div className="col-md-3 col-sm-4 pe-2 ps-2">
      <Card className={clsx(classes.card, "card shadow-xss")}>
        {isNew && (
          <a className="fw-600 ms-auto font-xsssss text-primary ps-2 pe-2 pb-0 pt-0 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm position-absolute top---15 mb-5 ms-2 right-15">
            New
          </a>
        )}
        <CardContent className={clsx(classes.cardContent, "text-center", "mycard")}>
          {profileeach.img ? (
            <figure
              className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
              onClick={toSpecificUserPage}
            >
              <img
                src={profileeach.img.slice(0, 4) === "http" ? profileeach.img : process.env.REACT_APP_API_URL + profileeach.img}
                alt="avatar"
                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
              />
            </figure>
          ) : (
            <figure
              className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
              onClick={toSpecificUserPage}
            >
              <img
                src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                alt="avatar"
                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
              />
            </figure>
          )}

          <h4 className="fw-700 font-xsss mt-3 mb-0">
            {profileeach.nickName}{" "}
          </h4>
        </CardContent>
        <CardActions className="mycard">
          <Grid container justifyContent="center">
            {profile.id ? (
              <>
                {profileeach.id === profile.id ? (
                  <Grid item>
                    <button
                      className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white"
                      onClick={toSpecificUserPage}
                    >
                      {t('RecommenduserEach1')}
                    </button>
                  </Grid>
                ) : (
                  <Grid item>
                    {!isFollowed ? (
                      <button
                        className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                        onClick={createNewRequest}
                      >
                        {t('RecommenduserEach2')}
                      </button>
                    ) : (
                      <button
                        className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white"
                        onClick={handleUnfollow}
                      >
                        {t('RecommenduserEach3')}
                      </button>
                    )}
                  </Grid>
                )}
              </>
            ) : (
              <Grid item>
                <button
                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white"
                  onClick={toSpecificUserPage}
                >
                  {t('Go to user page')}
                </button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};

export default RecommenduserEach;
