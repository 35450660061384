const SpecificUserPageDatasEach = (props) => {
    return (
        <div className="row mb-3">
            <div className="ms-2 me-2 col-md-8 ">
                <p className="font-xsss text-grey-600 fw-600 mb-0">{props.item.title}</p>
                <p className="font-xsssss text-grey-600 fw-600 mb-0">{props.item.created}</p>
            </div>
        </div>
    )
}

export default SpecificUserPageDatasEach;