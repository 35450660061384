import React, { 
    // useReducer, 
    useState,
    Fragment,
    useContext,
    useEffect
 } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import { ApiContext } from '../context/ApiContext';
import { useTranslation } from 'react-i18next';

const GetPage = (props) => {
    const [processing, setProcessing] = useState(false);
    const history = useHistory();
    const { intpage } = useContext(
        ApiContext,
    );
    const checkoutSingleItem = () => {
        setProcessing(true);
        axios.post(
            process.env.REACT_APP_API_URL + "/api/user/create-checkout-session/",
            {
                SITE_URL : process.env.REACT_APP_PUBLIC_URL,
                pageId: intpage.id.toString()
            },
            {
                headers: { "Content-Type": "application/json" },
            }
        ).then(res => {
            window.location.replace(res.data.goTo);
        }).catch(err => {
            console.log(err);
        });
    }

useEffect(()=>{
    console.log("intpage---", intpage)
    if(!intpage.id) {
        history.push("/home");
    }
}, [])
const { t } = useTranslation();
    return (
        <Fragment> 
            <div className="main-wrap">
                <div className="nav-header bg-transparent shadow-none border-0">
                    <div className="nav-top w-100">
                        <Link to="/home"><i className="feather-zap text-success display1-size me-2 ms-0"></i><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Tatami </span> </Link>
        
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                    style={{backgroundImage: `https://ow6r85j5w096clt.site/media/image/login.jpg`}}></div>
                        <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 display1-size display2-md-size mb-3">{t('GetPage1')} <br />{t('GetPage2')}</h2>
                                        <div className="col-sm-12 p-0 text-left">
                                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32"> </h6>
                                        <div className="form-group mb-1">
                                          <button style={{display:"flex",justifyContent:'center'}} onClick={checkoutSingleItem} className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 mt-5 "> { processing && <CircularProgress style={{alignSelf:'center', marginRight:'10px'}} /> } {t('GetPage3')}</button>
                                        </div>
                                        </div>
                                </div>
                            </div> 
                        </div>
                </div>
            </div>
        </Fragment>
    );
}

export default GetPage;