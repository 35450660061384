import React, { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const RequestleftEach = (profileeach, mutualFlag) => {
  const history = useHistory()
  const { profile, newRequestFriend, followinguser, deleteFriendRequest } = useContext(ApiContext);
  const createNewRequest = () => {
      const uploadDataAsk = new FormData();
      uploadDataAsk.append("askTo", profileeach.profileeach.userProfile)
      uploadDataAsk.append("approved", false);
      newRequestFriend(uploadDataAsk);
  }

  

  const toSpecificUserPage = () => {
      if (profile.userProfile === profileeach.profileeach.userProfile) {
          console.log("本人");
          history.push("/userpage");
      } else {

          history.push(`/user/${profileeach.profileeach.userProfile}`);
      }
  };
  const { t } = useTranslation();


  return (
    <div className="wrap">
      <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
        {
          profileeach.profileeach.img ?
          <figure
          className="avatar me-3 pointer"
          onClick={() => toSpecificUserPage()}
        >
          <img
            src={process.env.REACT_APP_API_URL + profileeach.profileeach.img}
            alt="avator"
            className="shadow-sm rounded-circle w45"
          />
        </figure>:
          <figure
          className="avatar me-3 pointer"
          onClick={() => toSpecificUserPage()}
        >
          <img
            src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
            alt="avator"
            className="shadow-sm rounded-circle w45"
          />
        </figure>
        }
        
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          {profileeach.profileeach.nickName}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {profileeach.profileeach.created_on}{t('RequestleftEach1')}
          </span>
        </h4>
      </div>
      <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-2">
      {/* {!profileeach.mutualFlag ? (
                            <button
                                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                                onClick={() => createNewRequest()}
                            >
                                {t('FriendRecommendslider2')}
                            </button>
                        ) : (
                            <button
                                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white"
                                onClick={() => deleteFriendRequest(profile.userProfile, profileeach.profileeach.userProfile)}
                            >
                                {t('FriendRecommendslider3')}
                            </button>
                        )} */}
      </div>
    </div>
  );
};
