import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ApiContext } from "../context/ApiContext";
import {
  Card,
} from "@material-ui/core";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  card: {
    maxWidth: 100,
    marginBottom: "1rem",
    maxHeight: 35,
  },
  cardContent: {
    minHeight: "130px",
  },
  media: {
    height: 140,
  }
});

const AttributeEachforModal = (attributeData) => {
  const { getPagesForIntAttribute } = useContext(ApiContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const toAttribute = () => {
    const attributeId = attributeData.attributeData.id;
    const params = new URLSearchParams(location.search);

    // 現在のattidと一致する場合は、paramからattidを削除
    if (parseInt(params.get("attid")) === attributeId) {
      params.delete("attid");
    } else {
      // 一致しない場合は、paramにattidを追加
      params.set("attid", attributeId);
      getPagesForIntAttribute(attributeId);
    }

    // URLを更新
    history.push({ search: params.toString() });
    attributeData.handleClose();
  };

  const { t } = useTranslation();

  // 現在のURLのクエリパラメータからattidを取得
  const params = new URLSearchParams(location.search);
  const attid = params.get("attid");

  // attidが現在のattributeData.attributeData.idと一致するかをチェック
  const isActive = parseInt(attid) === attributeData.attributeData.id;

  return (
    <>
      {isActive ?
        <Card className={clsx(classes.card, "card shadow-xss bg-white-transparent", "text-center ms-1 mb-1 me-1")}>
          <div className="p-0 pointer" onClick={toAttribute}>
            <h4 className="fw-700 text-current-dark font-xssss mt-1">
              {attributeData.attributeData.attributeName}
            </h4>
          </div>
        </Card>
        :
        <Card className={clsx(classes.card, "border-white card bg-transparent shadow-xss", "text-center ms-1 mb-1 me-1")}>
          <div className="card-body p-0 pointer" onClick={toAttribute}>
            <h4 className="fw-700 text-grey-700 font-xssss mt-1">
              {attributeData.attributeData.attributeName}
            </h4>
          </div>
        </Card>
      }
    </>
  );
};

export default AttributeEachforModal;
