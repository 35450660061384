import React, { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import {
  Card,
} from "@material-ui/core";
import { SnackbarContext } from '../context/SnackbarContext';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { propTypes } from "react-bootstrap/esm/Image";
import { useTranslation } from 'react-i18next';
import { ContactSupport } from "@material-ui/icons";
// import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    maxWidth:100,
    marginBottom: "1rem",
    maxHeight:35,
  },
  cardContent: {
    minHeight: "130px",
  },
  media: {
    height: 140,
  }
});

const AttributeEachforAdModal = (attributeData) => {
  const { newUserIntAttribute, userintatt, deleteUserIntAttribute } = useContext(ApiContext);
  const classes = useStyles();
  // const history = useHistory()



  const toAttribute = (props) => {

    newUserIntAttribute(attributeData.attributeData.id);
  };

  const { t } = useTranslation();
  const { newSnack } = useContext(SnackbarContext);


  const addattribute = (id) => {
    
    
    if (attributeData.tag.length <= 2 ){
      attributeData.setTag([...attributeData.tag, id]);
      attributeData.setTagName([...attributeData.tagName, attributeData.attributeData.attributeName])
      console.log("add");
      } else {
        newSnack("error", t('AttributeEachforAdModal1'));
        return
      }
  }

  const deleteattribute = (id) => {
    const newTags =attributeData.tag.filter(n=> n !== id)
    const newTagsNames =attributeData.tagName.filter(n=> n !== attributeData.attributeData.attributeName)
    attributeData.setTag(newTags)
    attributeData.setTagName(newTagsNames)
  }






  
  return (
    <>
    {attributeData.tag.includes(attributeData.attributeData.id) ?
    <Card className={clsx(classes.card, "card shadow-xss", "text-center ms-1 mb-1 me-1 bg-current")}>
    <div className=" pointer" onClick={() => {deleteattribute(attributeData.attributeData.id);}}>
      <h4 className="fw-700 text-white font-xssss mt-1 ">
        {" "}
        {attributeData.attributeData.attributeName}
      </h4>
    </div>

  </Card>
  :
    <Card className={clsx(classes.card, "card shadow-xss",
     "text-center ms-1 mb-1 me-1")}>
    <div className="card-body p-0 pointer" onClick={() =>addattribute(attributeData.attributeData.id)}>
      <h4 className="fw-700 text-grey-700 font-xssss mt-1 ">
        {" "}
        {attributeData.attributeData.attributeName}
      </h4>
    </div>

  </Card>
    }

    </>
  );
};

export default AttributeEachforAdModal;
