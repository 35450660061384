import React, { useState, useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import "./styles.css";
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from "../context/SnackbarContext";

const Createcomment = ({ commentTo, profileData, onCommentAdded }) => {
  const { profile, createdcomment, setCreatedcomment, createMonoComment } = useContext(ApiContext);
  const [commentBody, setCommentBody] = useState("");
  const { newSnack } = useContext(SnackbarContext);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setCreatedcomment({
      ...createdcomment,
      [name]: value,
      post: commentTo,
      userComment: profile.id,  // ここでuserCommentに現在のユーザーIDを設定
    });
    setCommentBody(value);
  };

  const saveComment = async () => {
    if (!profile.id) {  // ログインしていない場合
      newSnack("error", t('Header14')); // エラーメッセージを表示
      return;  // API通信を行わずに処理を終了
    }
  
    const newComment = await createMonoComment();
    if (newComment) {
      setCommentBody('');
      setCreatedcomment({ text: '', post: commentTo, userComment: profile.id }); // 状態をリセット
      if (onCommentAdded) {
        onCommentAdded(newComment); // 新しいコメントを親コンポーネントに渡す
      }
    }
  };
  


  const { t } = useTranslation();
  return (
    <form action="#">
      <div className="card w-100 shadow-xss rounded-xxl border-light-md ps-3 pt-0 pe-3 pb-2 mt-2">
        <div className="card-body p-0 mt-3 position-relative">
          {profile.img ? (
            <figure className="avatar position-absolute ms-2 mt-1 top-5">
              <img
                src={profile.img}
                alt="icon"
                className="shadow-sm rounded-circle w30"
              />
            </figure>
          ) : (
            <figure className="avatar position-absolute ms-2 mt-1 top-5">
              <img
                src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                alt="icon"
                className="shadow-sm rounded-circle w30"
              />
            </figure>
          )}
          <textarea
            onChange={handleInputChange} // ここで関数を直接渡す
            name="text"
            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xss text-grey-600 fw-500 border-light-md theme-dark-bg"
            cols="30"
            rows="10"
            value={commentBody}
            placeholder={t('Createcomment1')}
          ></textarea>
        </div>

        <div className="card-body p-0 mt-0 mb-1 position-relative">
          <div className="card-body p-0">
            {commentBody ? (
              <a
                href="#none"
                className="pointer bg-current text-center text-white font-xsss fw-600 pt-1 pb-1  w175 rounded-3 d-inline-block"
                onClick={saveComment}
              >
                {t('Createcomment2')}
              </a>
            ) : (
              <a
                href="#none"
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                disabled
              >
                {t('Createcomment3')}
              </a>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Createcomment;
