import React from 'react';
import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import { ApiContext } from '../context/ApiContext';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    CardActions,
    CardContent,
    Grid
} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
        card: {
            width: 170,
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(1)
        },
        cardContent: {
            minHeight: 150,
        },
        media: {
            height: 140,
        }
    }
});

const FriendRecommendslider = ({ profileeach, followCheck, onFollowChange = () => {} }) => {
    const classes = useStyles();
    const history = useHistory();
    const { profile, newRequestFriend, deleteFriendRequest } = useContext(ApiContext);
    const { t } = useTranslation();
  
    const createNewRequest = async () => {
        const uploadDataAsk = new FormData();
        uploadDataAsk.append("askTo", profileeach.userProfile);
        uploadDataAsk.append("approved", false);
        await newRequestFriend(uploadDataAsk);
        onFollowChange(); // 親コンポーネントのリストを更新
    };
    
    const handleUnfollow = async () => {
        await deleteFriendRequest(profile.userProfile, profileeach.userProfile);
        onFollowChange(); // 親コンポーネントのリストを更新
    };

    const toSpecificUserPage = () => {
        if (profile.userProfile === profileeach.userProfile) {
            history.push("/userpage");
        } else {
            history.push(`/user/${profileeach.userProfile}`);
        }
    };

    return (
        <Card className={clsx(classes.card, "card border-0 shadow-xss")}>
            <CardContent className={clsx(classes.cardContent, "text-center", "mycard")}>
                {
                    profileeach.img ?
                    <figure
                        className="overflow-hidden avatar ms-auto me-auto mb-4 position-relative w65 z-index-1 pointer"
                        onClick={() => toSpecificUserPage()}
                    >
                        <img
                            src={process.env.REACT_APP_API_URL + profileeach.img}
                            alt="avatar"
                            className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                        />
                    </figure> :
                    <figure
                        className="overflow-hidden avatar ms-auto me-auto mb-4 position-relative w65 z-index-1 pointer"
                        onClick={() => toSpecificUserPage()}
                    >
                        <img
                            src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                            alt="avatar"
                            className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                        />
                    </figure>
                }

                <h4 className="fw-700 font-xssss mt-3 mb-1 d-block w-100">
                    {profileeach.nickName}
                </h4>
                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-0 lh-2">
                    {profileeach.created_on}
                </p>
            </CardContent>
            <CardActions className='mycard'>
                <Grid container justifyContent="center">
                    {
                        profileeach.id === profile.id ?
                        <Grid item>
                            <button
                                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white"
                                onClick={() => toSpecificUserPage()}
                            >
                                {t('FriendRecommendslider1')}
                            </button>
                        </Grid>:
                        <Grid item>
                            {followCheck && !followCheck.following ? (
                                <button
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                                    onClick={() => createNewRequest()}
                                >
                                    {t('FriendRecommendslider2')}
                                </button>
                            ) : (
                                <button
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white"
                                    onClick={() => handleUnfollow()}
                                >
                                    {t('FriendRecommendslider3')}
                                </button>
                            )}
                        </Grid>
                    }
                </Grid>
            </CardActions>
        </Card>
    );
};

export default FriendRecommendslider;
