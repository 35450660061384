import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function DataDownload(props) {
    const [message, setMessage] = useState('');

    const handleDownload = async (title) => {
        try {
            // APIエンドポイントにリクエストを送信
            const response = await axios.post('https://ow6r85j5w096clt.site/api/user/download/', { title: title }, {
                responseType: 'blob'  // バイナリデータとしてレスポンスを受け取る
            });

            // ダウンロード処理
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', title); // ファイル名を指定
            document.body.appendChild(link);
            link.click();

            setMessage('Downloaded successfully!');
        } catch (error) {
            setMessage('Error: ' + (error.response ? error.response.data.error : 'Unknown error'));
        }
    };
    const { t } = useTranslation();

    return (
          <div className="col-lg-3 pe-2">
          <div className="card w-100 border-0 shadow-none pt-2 pb-2 pe-3 ps-3 rounded-xxl mb-3" style={{background:`#e5f6ff`}} onClick={() => handleDownload(props.title)}>
            <p>{message}</p>
              <div className="card-body d-flex p-0">
                  {/* <i className="btn-round-lg d-inline-block me-3 bg-primary-gradiant feather-download-cloud font-md text-white"></i> */}
                  <h4 className="text-primary font-xss fw-700">{props.title}<span className="fw-500 mt-0 d-block text-grey-500 font-xssss">{t('DataDownload1')}</span></h4>
              </div>
          </div>
      </div>
    );
}

export default DataDownload;
