import React, { useContext} from 'react';
import { ApiContext } from '../context/ApiContext'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const RelatedPages = ({ monopage, setCount }) => {  // 修正: オブジェクトのデストラクチャリング
    const { newUserIntPageWithScroll, monopages, getMonoPages, followingpage } = useContext(ApiContext);
    const history = useHistory();
    
    const toMonopage = () => {
        setCount(0);  // setCount を正しく呼び出す
        history.push("/mono/" + monopage.id);
    };

    const randnum = Math.floor(Math.random() * 29);
    const imageUrl = `https://ow6r85j5w096clt.site/media/image/background_${1 + randnum}.jpg`;

    const { t } = useTranslation();

    return (
        <div className="pointer">
            <div className="card w-30 shadow-xss rounded-xxl border-0 pt-3 ps-3 pe-3 pb-2 me-2 ms-2 mb-2">
                <div className="card-body p-0 d-flex pointer" onClick={() => toMonopage()}>
                    {
                    monopage.img ?
                    <figure className="avatar me-3"><img src={process.env.REACT_APP_API_URL + monopage.img} alt="avater" className="shadow-sm rounded-3 w45" /></figure> :
                    <figure className="avatar me-3"><img src={imageUrl} alt="avater" className="shadow-sm rounded-3 w45" /></figure>
                    }
                    
                    <h4 className="fw-700 text-grey-900 font-xssss"> {monopage.title}<span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {monopage.created_on}</span>
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default RelatedPages;
