import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    clock: {
        fontFamily: "'Share Tech Mono', monospace",
        color: '#fff0f5',
        // textShadow: '0 0 20px #0aafe6',
        lineHeight: '1.2',
        textAlign: 'center',
        padding: '0px',
        borderRadius: '5px',
        boxShadow: '',
        margin: '0px',
        background: '',
      },
      'clock-date': {
        fontSize: '15px',
        margin: '0px 0',
        // [theme.breakpoints.down('xs')]: {  // メディアクエリ
        //   fontSize: '5vw',
        // },
      },
      'clock-time': {
        fontSize: '30px',
        fontWeight: 'bold',
        margin: '0px 0',
        // [theme.breakpoints.down('xs')]: {  // メディアクエリ
        //   fontSize: '20vw',
        // },
      },
    }));




    const Clockforpage = () => {
      const classes = useStyles();
      const [date, setDate] = useState(new Date());
  
      useEffect(() => {
          const interval = setInterval(() => {
              setDate(new Date());
          }, 1000);
  
          return () => clearInterval(interval);
      }, []);
  
      const getFormattedTime = () => {
          const d = date;
  
          // タイマーの開始日を設定
          const startDate = new Date('2023-10-29');  // この日付を適切なものに設定してください
  
          // 経過日数を取得
          const elapsedDays = Math.floor((d - startDate) / (1000 * 60 * 60 * 24)) % 3;
  
          // 3日のうちの経過時間を取得
          const elapsedHours = (elapsedDays * 24) + d.getHours();
          const totalHours = 3 * 24;  // 3 days * 24 hours/day = 72 hours
  
          const hours = String(Math.floor((totalHours - elapsedHours - 1) % 24)).padStart(2, '0');
          const minutes = String(59 - d.getMinutes()).padStart(2, '0');
          const seconds = String(59 - d.getSeconds()).padStart(2, '0');
  
          return `${hours}:${minutes}:${seconds}`;
      };
      const time = getFormattedTime();
  
      return (
          <div className={classes.clock}>
            <p className={classes["clock-time"]}>{time}</p>
            <p className={classes["clock-date"]}>{date.toLocaleDateString()}</p> 
          </div>
      );  
  }
  
  export default Clockforpage;
