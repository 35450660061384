
import React, {  useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // アニメーションのキーフレームを定義
    '@keyframes fadeInUp': {
      '0%': {
        opacity: 0,
        transform: 'translateY(20px)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
    // アニメーションを適用するクラス
    fadeinUp: {
      animation: `$fadeInUp 0.7s ${theme.transitions.easing.easeInOut} forwards`,
    },
  }));
  

const OriginDataEach = (props) => {
    const classes = useStyles();
    const [isAnimated, setIsAnimated] = useState(false);
  // コンポーネントがマウントされた後にアニメーションを適用
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimated(true);
    }, 100); // 0.1秒後にアニメーションを開始
    return () => clearTimeout(timer);
  }, []);

    return (
        <div className={`${isAnimated ? classes.fadeinUp : ''} card bg-white-transparent w-100 shadow-xss rounded-xxl border-0 ps-3 pe-3 pt-2 pb-2 mb-1`}>
            <div className="ms-2 me-2 col-md-8 ">
                <p className="font-xsss text-current-dark fw-600 mb-0">{props.item}</p>
            </div>

        </div>
    )
}

export default OriginDataEach;