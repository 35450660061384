import React from "react";
import { useContext, useEffect } from "react";
import { ApiContext } from "../context/ApiContext";
import { RequestleftEach } from "../components/RequestleftEach";
import { useTranslation } from 'react-i18next';

const Requestleft = () => {
  const {
    profile,
    getFollowedUser,
    followeduser,
  } = useContext(ApiContext);

  const listProfiles =
    profile.id &&
    followeduser.map((user) => (
      <RequestleftEach
        key={user.profile.id}  // keyにはuser.profile.idを使用
        profileeach={user.profile}  // profileeachにはuser.profileを渡す
        mutualFlag={user.mutualFollow}  // mutualFlagにはuser.mutualFollowを渡す
      />
      )).slice(0,2);
      

      const { t } = useTranslation();

  useEffect(() => {
    if (profile.id) {
      getFollowedUser(profile.userProfile);
    }
  }, [profile.id]); // profile.id の変更を監視


  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-0 mb-3">
      <div className="ard-body d-flex align-items-center ps-4 pe-4 pt-3 pb-3">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">
        {t('Requestleft1')}
        </h4>
        <a href="/followed" className="fw-600 ms-auto font-xssss text-primary">
        {t('Requestleft2')}
        </a>
      </div>
      {listProfiles}
    </div>
  );
};

export default Requestleft;
