import React, { useCallback, useState, useEffect, useRef } from "react";
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import { Dialog,  DialogContent, DialogTitle } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import "./styles.css";
import { useTranslation } from 'react-i18next';
import AttributesModalforAd from "./AttributesModalforAd";
import i18n from '../i18n'; 

export const ASPECT_RATIO = 2 / 1;
export const CROP_WIDTH = 400;

const inital_state = {
    type: 0,
    target: 0,
    url: "",
    content: ""
}

const AdsModal = (props) => {

    const file = useRef();
    const [isShowAttributesModal, setShowAttributesModal] = useState(false);

    const [imgSrc, setImgSrc] = useState("");

    const [preview, setPreview] = useState(false);

    const [processing, setProcessing] = useState(false);

    const [checkbox, setCheckbox] = useState(false);

    const [targets_imp, setTargetsimp] = useState([90000, 200000, 750000]);

    const [targets_click, setTargetsclick] = useState([3000, 10000, 25000]);
    const [language, setLanguage] = useState(i18n.language);

    const [adsData, setAdsData] = useState(inital_state);

    const [tag, setTag] = useState([]);
    const [tagName, setTagName] =useState([]);

    const { postAds,  allowAds } = useContext(ApiContext);

    const imgStyle = {
        borderRadius: '15px',
        padding: '2px',
        // border: '1px solid gray',
        maxHeight: '130px'
    }

    const post = async () => {
        setProcessing(true);
        const data = new FormData();
        data.append("type", adsData.type);
        {adsData.type === 0 ? data.append("target", targets_imp[adsData.target]):data.append("target", targets_click[adsData.target])};
        data.append("url", adsData.url);
        data.append("content", adsData.content);
        data.append("updateCnt", 0);
        data.append("updateDelFlag", 1);
        data.append("img", file.current.files[0]);
        if (Array.isArray(tag)) {
            tag.forEach((v, i) => {
              data.append("attributes", v)
            })
          } else {
            data.append("attributes", tag)
          }
        await postAds(data);
        // setImgSrc("");
        // setAdsData(inital_state);
        // props.handleClose();
    };

    const handleAttributesClick = (number) => {
        setShowAttributesModal(true)
      }


    const handleAttributesModalClose = () => {
        setShowAttributesModal(false);
    }

    const onFileChange = useCallback(async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                if (reader.result) {
                    setImgSrc(reader.result.toString() || "");
                }
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }, [adsData]);

    const handleInputChange = () => (event) => {
        const value = event.target.value;
        const name = event.target.name;
        // console.log(adsData);
        setAdsData({ ...adsData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        setCheckbox(e.target.checked);
    };

    useEffect(async () => {
        const params = new URLSearchParams(window.location.search);
        const queryValue = params.get('success');
        const session_id = params.get('session_id');
        const encrypted_pageId = params.get('indexAds');
        if(queryValue === "true" && session_id && encrypted_pageId) {
            await allowAds(encrypted_pageId)
        }
    }, []);
    const { t } = useTranslation();

    return (
        <Dialog
            className="animated fadeIn "
            open={props.isVisible}
            onClose={props.handleClose}
            aria-labelledby="max-width-dialog-title"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle className="mycard bg-current" id="max-width-dialog-title"><a className="fw-600 text-white font-xs">{t('AdsModal1')}</a></DialogTitle>
            <DialogContent className="mycard card">
                <AttributesModalforAd
                    isVisible={isShowAttributesModal}
                    handleClose={handleAttributesModalClose}
                    tag = {tag}
                    lan = {language}
                    tagName = {tagName}
                    setTag = {setTag}
                    setTagName = {setTagName}
                />
                <div className="form-group">
                    <label className="mont-font fw-600 font-xsss mb-2 text-grey-600">
                    {t('AdsModal2')}
                        {adsData.content ? <></> : <span className="text-danger font-xs">{" "} *</span>}
                    </label>
                    <textarea
                        value={adsData.content}
                        onChange={handleInputChange()}
                        name="content"
                        className="h100 bor-0 w-100 rounded-xxl p-2 font-xss fw-500 text-grey-600 border-light-md theme-dark-bg"
                        cols="20"
                        rows="10"
                        placeholder={t('AdsModal2')}
                    />
                </div>
                <div className="form-group">
                    <label className="mont-font fw-600 font-xsss mb-2 text-grey-600">
                    {t('AdsModal3')}
                    </label>
                    {!adsData.url  ?<span className="text-danger font-xs">{" "} *</span>: <></> }
                    <input
                        value={adsData.url}
                        type="text"
                        className="form-control font-xss"
                        name="url"
                        onChange={handleInputChange()}
                    />
                </div>
                <div className="form-group">
                <label className="mont-font fw-600 font-xsss text-grey-600 d-flex">
                <h2 className="  mont-font mt-2 fw-600 font-xsss  text-grey-600">
                {t('AdsModal16')}
                        </h2> 
                
                </label>
                <div className="d-flex">
                {tagName.map((value,index)=>{
                    return <h2 className="card shadow-xs mt-2 ms-2 ps-2 pe-2 pt-1 pb-1 font-xssss  lh-1 text-current fw-600">
                    {value}
                        </h2>
                })}
                </div>

                {adsData.target === "1"  | adsData.target === "2"  ?

                <div>
                <Button
                    className={"full-width bg-current mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-4 font-xsssss fw-700 ls-lg text-white"}
                    variant="contained"
                    fullWidth={true}
                    component="label"
                    onClick={() => handleAttributesClick()}
                >
                    {t('AdsModal17')}
                    {tag ? <></> : <span className="text-danger font-xs">{" "} *</span>}
                </Button>
                {tag.length >= 3 ?<></>: <span className="text-danger font-xssss">{" "} {t('AdsModal18')}</span> }
                    </div>:

                <div>


                <Button
                        disabled
                        className="full-width mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-4 btn-dark font-xsssss fw-700 ls-lg text-white"
                        variant="contained"
                        fullWidth={true}
                        component="label"
                        // onClick={() => handleAttributesClick()}
                    >
                        {t('AdsModal17')}
                        {tag ? <></> : <span className="text-danger font-xs">{" "} *</span>}
                    </Button>
                </div>

                }
                



                </div>
                <div className="row mb-4">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2 text-grey-600">
                            {t('AdsModal4')}
                            </label>
                            {adsData.type === 0 ? <span className="text-danger font-xs">{" "} *</span>:<></> }
                            <select
                                className="border-2 form-select"
                                name="type"
                                value={adsData.type}
                                onChange={handleInputChange()}
                            >
                                <option value={0}>{t('AdsModal5')}</option>
                                <option value={1}>{t('AdsModal6')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss mb-2 text-grey-600">
                            {t('AdsModal7')}
                            </label>
                            {adsData.target === 0 ? <span className="text-danger font-xs">{" "} *</span>:<></> }
                            {adsData.type === 0 ?
                            <select
                            className="border-2 form-select"
                            name="target"
                            value={adsData.target}
                            onChange={handleInputChange()}
                        >
                            {
                                targets_imp.map((value, index) => {
                                    return <option key={index} value={index}>{value}回</option>
                                })
                            }
                        </select>:
                        <></>}

                        {/* {adsData.target === 0 ? <span className="text-danger font-xs">{" "} *</span>:<></> } */}
                        {adsData.type ==! 0 ?
                        <select
                        className="border-2 form-select"
                        name="target"
                        value={adsData.target}
                        onChange={handleInputChange()}
                        >
                        {
                            targets_click.map((value, index) => {
                                return <option key={index} value={index}>{value}回</option>
                            })
                        }
                        </select>:
                        <></>}
                        </div>

                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-md-6 col-sm-12 mb-2 d-flex justify-content-center">
                        {
                            imgSrc ?
                                <img style={imgStyle} src={imgSrc} maxWidth={"100%"} />
                                :
                                <div style={{ ...imgStyle, height: '100%' }} className="card d-flex full-width justify-content-center align-items-center font-xsss border-light-md shadow-xss text-grey-600 ">
                                    {t('AdsModal8')}
                                </div>
                        }
                    </div>
                    <div className="row col-md-6 col-sm-12">
                        <div className="col-md-12 mb-1">
                        <a href="https://tatami.tokyo/pricing/">
                            <label className="form-check-label font-xsss text-grey-600 text-decoration-underline pointer">{t('AdsModal19')}</label>
                            </a>
                        <div className="form-check text-left mb-3">
                            <input 
                                type="checkbox" 
                                className="form-check-input mt-2" 
                                id="exampleCheck2" 
                                checked={checkbox}  // チェックボックスの状態を管理する
                                onChange={handleCheckboxChange}  // チェックボックスの状態が変わったときのイベントハンドラ
                            />
                            <a href="https://tatami.tokyo/terms-conditions-ad/">
                            <label className="form-check-label font-xsss text-grey-600 text-decoration-underline pointer">{t('AdsModal9')}</label>
                            </a>
                        </div>
                        </div>
                        <div className="col-md-12 mb-1">
                            <Button
                                className={"full-width bg-current mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-4 font-xsssss fw-700 ls-lg text-white"}
                                variant="contained"
                                fullWidth={true}
                                component="label"
                            >
                                {t('AdsModal10')}
                                {imgSrc ? <></> : <span className="text-danger font-xs">{" "} *</span>}
                                <input type="file" hidden onChange={onFileChange} ref={file} />
                            </Button>
                        </div>
                        <div className="col-md-12 mb-1">
                            {
                                imgSrc ?
                                    <button
                                        onClick={() => setPreview(true)}
                                        className="full-width bg-current mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-4 font-xsssss fw-700 ls-lg text-white"
                                    >
                                        {t('AdsModal11')}
                                    </button>
                                    :
                                    <button
                                        disabled
                                        className="full-width mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-4 btn-dark font-xsssss fw-700 ls-lg text-white"
                                    >
                                        {t('AdsModal12')}
                                    </button>
                            }
                        </div>
                        <div className="col-md-12 mb-1">
                            {
                                imgSrc && adsData.content && checkbox ?
                                    <button
                                        onClick={post}
                                        style={{justifyContent:'center'}}
                                        className="full-width bg-current mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-flex rounded-4 font-xsssss fw-700 ls-lg text-white"
                                    >
                                        { processing && <CircularProgress size={20} style={{alignSelf:'center', marginRight:'10px'}} /> }
                                        {t('AdsModal13')}
                                    </button>
                                    :
                                    <button
                                        disabled
                                        className="full-width mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-4 btn-dark font-xsssss fw-700 ls-lg text-white"
                                    >
                                        {t('AdsModal14')}
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </DialogContent>
            <Dialog onClose={() => setPreview(false)} aria-labelledby="simple-dialog-title" open={preview}>
                <DialogTitle id="simple-dialog-title">{t('AdsModal15')}</DialogTitle>
                <img src={imgSrc} />
            </Dialog>
        </Dialog >
    );
};

export default AdsModal;
