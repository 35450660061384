import React, {
  Fragment, useContext, useEffect, useState
} from "react";
import { ApiContext } from "../context/ApiContext";
import { Link } from "react-router-dom";
import {
  Button,
  // makeStyles
} from "@material-ui/core";
import { useCookies } from "react-cookie";
// import { useRouter } from "next/navigation";
import { useHistory, useLocation } from "react-router-dom";
import { Layout } from "@stripe/stripe-js";
import axios from "axios";
import { HistoryRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import { SnackbarContext } from "../context/SnackbarContext";
import { useTranslation } from 'react-i18next';

export const ASPECT_RATIO = 1 / 1;
export const CROP_WIDTH = 400;
const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: 30,
      minWidth: "100%",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      flexFlow: "column",
      "& .file-upload-container": {
        width: 500,
        marginTop: 10,
        "& .button": {
          backgroundColor: "#00A0FF",
          color: "white",
        },
      },
      "& .img-container": {
        marginTop: 40,
        width: `${CROP_WIDTH}px`,
        height: `${CROP_WIDTH / ASPECT_RATIO}px`,
        display: "flex",
        alinItems: "center",
        borderRadius: 5,
        border: "1px solid gray",
        overflow: "hidden",
        backgroundColor: "#EAEAEA",
        "& .img": {
          width: "100%",
          objectFit: "contain",
          backgroundColor: "#EAEAEA",
        },
        "& .no-img": {
          backgroundColor: "#EAEAEA",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#000",
        },
      },
    },
    validationWrapper: {
      float: "right"
    }
  }
})


const BankAccount = () => {
  const classes = useStyles();
  const [cookie] = useCookies(["current-token"]);

  const [token, setToken] = useState(localStorage.getItem('current-token'))
  const {
    profile,
    //  editedProfile, setEditedProfile, deleteProfile, setCover, coverBack, createProfile, editProfile
  } = useContext(ApiContext);
  const { newSnack } = useContext(SnackbarContext);

  const [cookies, setCookie, removeCookie] = useCookies(["current-token"]);
  const Logout = () => {
    removeCookie("current-token");
    window.location.replace("/login");
  };
  const [loginLinkUrl, setLoginLinkUrl] = useState(null);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL +`/api/user/get-my-stripe-account-id`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then(response => {
        const shopId = response.data.shopId;
        return axios.get(process.env.REACT_APP_API_URL + `/api/user/get-login-link?shopId=${shopId}`
        , {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
        );
      })
      .then(response => {
        setLoginLinkUrl(response.data.url);
      })
      .catch(error => {
        console.error("Error fetching the Stripe login link", error);
      });
  }, []);


  // const router = useRouter()
  const history = useHistory();

  const getSetLink = async () => {
    if (! profile.userProfile) {
      newSnack("error", t('BankAccount1'));
      return;
    }
    else 
    {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/api/user/create-connect-account/",
      {
        name: "test",
        email: "test@mail.com",
      },
      {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error fetching the Stripe URL", error);
    }
  }
}

  const { t } = useTranslation();

  return (
  <Fragment>
    <Header />
    <Leftnav />
    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                <Link to="/settings" className="d-inline-block mt-2">
                  <i className="ti-arrow-left font-sm text-white"></i>
                </Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                {t('BankAccount2')}
                </h4>
              </div>

              <div className="card mt-2 w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center " 
              style={{backgroundImage: `url("assets/images/background_bankaccount.jpg")`}}>
                  <div className="card-body p-md-5 p-4 text-center" style={{backgroundColor:'rgba(71,120,79,0.8)'}}>
                      <h2 className="fw-700 display2-size text-white display2-md-size lh-2">{t('BankAccount3')}</h2>
                      <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">{t('BankAccount4')}</p>
                  </div>
              </div>
              <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                  <Accordion allowZeroExpanded className="accodion-style--1 accordion" preExpanded={'0'}>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('BankAccount5')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                          <p>{t('BankAccount6')}</p>
                          <div className="col-xl-8 col-xxl-9 col-lg-8 ">
                            <div className="pe-2 ">
                          
                              <img
                                src="assets/images/howto_topic.png"
                                alt="avater"
                                className="rounded-3 img-fluid w-100"
                              />
                            </div>
                          </div>
                          </AccordionItemPanel>
                      </AccordionItem>
                      {/* <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('BankAccount7')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('BankAccount8')}</p>
                          </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('BankAccount9')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                          <p>{t('BankAccount10')}</p>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem className="card shadow-xss">
                          <AccordionItemHeading className="card-header">
                              <AccordionItemButton>
                                  <h5 className="fw-600 pt-2 pb-2 mb-0">{t('BankAccount11')}</h5>
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="card-body">
                              <p>{t('BankAccount12')}</p>
                          </AccordionItemPanel>
                      </AccordionItem>
 */}

                  </Accordion>
              </div>

              <div className="card-body p-lg-5 p-4 w-100 border-0">
              <div className="row justify-content-center mb-4">

                <div className="col-lg-12 text-center">
                  <Button
                    className="bg-current text-center text-white font-xsss fw-600 p-3 ms-1 me-1 w175 rounded-3 mt-3"
                    variant="contained"
                    component="label"
                    onClick={() => getSetLink()}
                  >
                    {t('BankAccount13')}
                  </Button>
                  {loginLinkUrl ?
                  <a href={loginLinkUrl}>
                  <Button
                    className="bg-current text-center text-white font-xsss fw-600 p-3 ms-1 me-1 w175 rounded-3 mt-3"
                    variant="contained"
                    component="label"
                    onClick={() => getSetLink()}
                  >
                    {t('BankAccount14')}
                  </Button>
                  </a>
                  :
                  <Button
                    className="bg-grey-300 text-center text-white font-xsss fw-600 p-3 ms-1 me-1 w175 rounded-3 mt-3"
                    variant="contained"
                    component="label"
                    onClick={() => getSetLink()}
                  >
                    {t('BankAccount15')}
                  </Button>

                  }
                </div>
                
              <a className="fw-400 text-dark font-xss mt-3 mb-5 text-center">{t('BankAccount16')}</a>

              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Appfooter />
    </Fragment>
  );
};

export default BankAccount;
