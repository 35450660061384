const PostedDataEach = (props) => {
    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-3 pe-3 pt-2 pb-2 mb-3">

                
            <div className="ms-2 me-2 col-md-8 ">
                <p className="font-xsss text-grey-600 fw-600 mb-0">{props.item.title}</p>
                <p className="font-xsssss text-grey-600 fw-600 mb-0">{props.item.created}</p>
            </div>

        </div>
    )
}

export default PostedDataEach;